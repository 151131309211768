import { Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import { gender } from '../../../common/constants/dropDownList';
import { GridColDef } from '@mui/x-data-grid';
function Employee() {
  const loading = false;
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      headerName: 'EMP ID',
      field: 'emp_reg_no',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: 'Name',
      field: 'first_name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: 'Mobile',
      field: 'mobile',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: 'Personal Email',
      field: 'personal_email',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'secondary_mobile',
      headerName: 'Secondary Mobile',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'DOB',
      field: 'dob',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'gender_type',
      headerName: 'Gender',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: ({ row }: any) =>
        gender.find((data) => data.Code === row?.gender_type)?.Category,
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) => {
        const address = `${row?.address}, ${row?.city}, ${row?.state}, ${row?.country}.`;
        return (
          <Tooltip title={address}>
            <Typography>
              <Typography>{row?.address},</Typography>
              <Typography>{row?.city},</Typography>
              <Typography>{row?.state},</Typography>
              <Typography>{row?.country}.</Typography>
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      headerName: 'ACTION',
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.EDITEMPLOYEE, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.VIEWEMPLOYEE, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        return (
          <>
            {' '}
            <CustomButton
              text={'VIEW'}
              className='mx-3'
              color={'secondary'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={'EDIT'}
              className='mx-3 '
              onClick={handleEditClick}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      personal_email: 'syt@gmail.com',
      emp_reg_no: 'GF-002',
      secondary_mobile: '33232',
      gender_type: 'M',
      dob: '2001-11-11',
      address: '3232',
      city: 'NAMAKKAL',
      state: 'TMAILNADU',
      country: 'INDIA',
      id: 'a96907cd-1072-40c8-977e-9f8140962f35',
      first_name: 'surya',
      last_name: 'vishu',
      email: 'surya.v@yaacreations.com',
      mobile: '989877293',
      auth_code: null,
      bunk_id: null,
      bunk_details_id: null,
      user_type: 1,
      status: 1,
      created_at: '2024-08-07T13:50:27.000Z',
      updated_at: '2024-08-07T13:50:27.000Z',
      deleted_at: null,
      userProfiles: [
        {
          id: '75eb3f15-43ef-4a91-aeb8-5d9e58d1bca5',
          personal_email: 'syt@gmail.com',
          emp_reg_no: 'GF-002',
          secondary_mobile: '33232',
          gender_type: 'M',
          dob: '2001-11-11',
          user_id: 'a96907cd-1072-40c8-977e-9f8140962f35',
          role_id: '4453b11b-7de3-42dc-9b8a-902af97fe4e3',
          address: '3232',
          city: null,
          state: '32d',
          country: '33',
          profile_url: '',
          employee_type: 0,
        },
      ],
    },
    {
      id: '6b2e5c12-d322-4a2e-804e-4cc19186f099',
      first_name: 'surya',
      last_name: 'vishu',
      email: 'surya22.v@yaacreations.com',
      mobile: '989877243',
      auth_code: null,
      bunk_id: null,
      bunk_details_id: null,
      user_type: 1,
      status: 1,
      created_at: '2024-08-07T13:49:54.000Z',
      updated_at: '2024-08-07T13:49:54.000Z',
      deleted_at: null,
      personal_email: 'syt@gmail.com',
      emp_reg_no: 'GF-002',
      secondary_mobile: '33232',
      gender_type: 'M',
      dob: '2001-11-11',
      address: '3232',
      city: 'NAMAKAAL',
      state: 'TMAILNADU',
      country: 'INDIA',
      userProfiles: [
        {
          id: '9352fb19-ca86-470a-8f30-b0acf7a6d921',
          personal_email: 'syt@gmail.com',
          emp_reg_no: 'GF-001',
          secondary_mobile: '33232',
          gender_type: 'M',
          dob: '2001-11-11',
          user_id: '6b2e5c12-d322-4a2e-804e-4cc19186f099',
          role_id: null,
          address: '3232',
          city: null,
          state: '32d',
          country: '33',
          profile_url: '',
          employee_type: 0,
        },
      ],
    },
    {
      id: '3',
      emp_reg_no: 'GF-003',

      address: '3/82D ,Kurinji Nagar, Namakkal',
      bunk_details_id: '1',
      city: 'NAMAKKAL',
      country: 'India',
      dob: '1998-09-05',
      email: 'arunkumar@gmail.com',
      first_name: 'keerthi',
      gender_type: 'M',
      last_name: 'v',
      mobile: '8220894345',
      password: 'KERTHI@',
      personal_email: '',
      pincode: '895584',
      role_id: '1',
      secondary_mobile: '',
      state: 'TN',
    },
    {
      id: 'feb039ea-11e8-40bd-b39b-c7ca60bb464e',
      personal_email: 'syt@gmail.com',
      emp_reg_no: 'GF-001',
      secondary_mobile: '33232',
      gender_type: 'M',
      dob: '2001-11-11',
      user_id: 'd113facd-c767-48d7-b959-c8739b2460f1',
      role_id: '2',
      address: '3232',
      city: null,
      state: '32d',
      country: '33',
      profile_url: '',
      employee_type: 0,
      role_name: 'manager',
      first_name: 'surya',
      last_name: 'vishu',
      email: 'surya.v@yaacreations.com',
      mobile: '98987726',
    },
  ];
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={'Employee'}
          buttonName={'Add Employee'}
          route={APP_ROUTES.ADDEMPLOYEE}
          buttonIcon={AddIcon}
        />
      </Grid>

      {loading ? (
        <>
          <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
            <CircularIndeterminate />
          </Grid>{' '}
        </>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
    </Grid>
  );
}
export default Employee;
