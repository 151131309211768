import { Grid } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import DispenserFrom from './DispenserFrom';
import { useTranslation } from 'react-i18next';

function ViewDispenser() {
  const location = useLocation();
  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      nozzle: location.state.data?.data?.nozzle || '',
      dispenser_model: location.state.data?.data?.dispenser_model || '',
      dispenser_type: location.state.data?.data?.dispenser_type || '',
      bunk_details_id: location.state.data?.data?.bunk_details_id || '',
      otp_date: location.state.data?.data?.otp_date || '',
      dispenser_maker: location.state.data?.data?.dispenser_maker || '',
      metering_unit_1: location.state.data?.data?.metering_unit_1 || '',
      metering_unit_2: location.state.data?.data?.metering_unit_2 || '',
      metering_unit_3: location.state.data?.data?.metering_unit_3 || '',
      metering_unit_4: location.state.data?.data?.metering_unit_4 || '',
      totalizer_1: location.state.data?.data?.totalizer_1 || '',
      totalizer_2: location.state.data?.data?.totalizer_2 || '',
      totalizer_3: location.state.data?.data?.totalizer_3 || '',
      totalizer_4: location.state.data?.data?.totalizer_4 || '',
    },
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.DISPENSER);
  };
  const { t } = useTranslation();
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('DISPENSER_CALLIBARTRION_DETAILS')}
          route={APP_ROUTES.DISPENSER}
          buttonName={t('GO_BACK')}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid xs={12}>
        <DispenserFrom
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          view={true}
          watch={watch}
          setValue={setValue}
        />
      </Grid>
    </Grid>
  );
}

export default ViewDispenser;
