import React from 'react';
import CommonModal from './CommonModal';
import CustomButton from '../CustomButton';
import { Grid, Typography } from '@mui/material';

const DeleteModel = ({
  open,
  setOpen,
  success,
}: {
  open: any;
  setOpen: any;
  success: any;
}) => {
  const handledelete = () => {
    success();
    setOpen(false);
  };
  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName='px-20 py-20'
      boxProps={{ width: 300, height: 150 }}
    >
      <Grid>
        <Typography textAlign={'center'} mb={5}>
          Are You Sure Want to delete?
        </Typography>
      </Grid>
      <Grid display={'flex'} justifyContent={'space-around'}>
        <CustomButton
          text={'Cancel'}
          className='px-5 py-3'
          color={'secondary'}
          onClick={() => setOpen(false)}
        />
        <CustomButton
          text={'Delete'}
          className='px-5 py-3 brown-color'
          onClick={handledelete}
        />
      </Grid>
    </CommonModal>
  );
};

export default DeleteModel;
