import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
} from '@mui/material';
import {
  LocalGasStation,
  LocalShipping,
  Speed,
  Group,
} from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import DashboardCard from './DashboardCard';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DashboardScreen() {
  // Sample data for the chart
  const { t } = useTranslation();
  const data = {
    labels: ['Petrol', 'Diesel'],
    datasets: [
      {
        label: 'Values',
        data: [50000, 1000], // Corresponding values for each category
        backgroundColor: ['#FF5733', '#33C1FF'],
        borderColor: ['#FF5733', '#33C1FF'],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {},
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <Grid>
      {/* Top Metrics */}
      <Grid
        item
        xs={12}
        container
        display='flex'
        justifyContent={'space-between'}
        columnSpacing={5}
      >
        {/* Petrol Card */}
        <Grid item flexGrow={1} my={2}>
          <DashboardCard
            total={'25000 L'}
            content={t('TOTAL_AVAILABLE_PETROL')}
            // classname={'blue-back'}
            icon={
              <LocalGasStation sx={{ fontSize: 40, color: 'secondary.main' }} />
            }
            navaigate={APP_ROUTES.FUEL}
            linearValue={30}
          />
        </Grid>

        {/* Diesel Card */}
        <Grid item flexGrow={1} my={2}>
          <DashboardCard
            total={'1,000 l'}
            content={t('TOTAL AVAILABLE DIESEL')}
            // classname={'blue-back'}
            icon={
              <LocalGasStation sx={{ fontSize: 40, color: 'success.main' }} />
            }
            navaigate={APP_ROUTES.FUEL}
            linearValue={50}
          />
        </Grid>

        {/* Dip Reading Card */}
        <Grid item flexGrow={1} my={2}>
          <DashboardCard
            total={'1,000 l'}
            content={t('TODAY DIP READING')}
            // classname={'blue-back'}
            icon={<Speed sx={{ fontSize: 40, color: 'warning.main' }} />}
            navaigate={APP_ROUTES.DIP}
          />
        </Grid>

        {/* Employees Card */}
        <Grid item flexGrow={1} my={2}>
          <DashboardCard
            total={'10'}
            content={t('NO. OF EMPLOYEES')}
            // classname={'blue-back'}
            icon={<Group sx={{ fontSize: 40, color: 'primary.main' }} />}
            navaigate={APP_ROUTES.EMPLOYEE}
          />
        </Grid>
      </Grid>

      {/* Chart Section */}
      <Box sx={{ mt: 4 }} width={600}>
        <Typography variant='h6' gutterBottom>
          Metrics Overview
        </Typography>
        <Bar data={data} options={options} />
      </Box>
    </Grid>
  );
}

export default DashboardScreen;
