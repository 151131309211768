import { Grid } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { gender, Role } from '../../../common/constants/dropDownList';
import { useLocation } from 'react-router-dom';

function EmployeeFrom({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
}) {
  const location = useLocation();
  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='center'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'FirstName'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='first_name'
              type='text'
              placeholder='Name..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a Name',
                },
              }}
              inputProps={{
                ...register('first_name', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'LastName'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='last_name'
              type='text'
              placeholder='Name..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a Name',
                },
              }}
              inputProps={{
                ...register('last_name', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Mobile number'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='mobile'
              type='text'
              placeholder='Mobile..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the mobile',
                },
                maxLength: {
                  value: 10,
                  message: 'Mobile Number should not exceed 10 digits',
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Only numeric characters allowed',
                },
                minLength: {
                  value: 10,
                  message: 'Mobile Number should exceed atleast 10 digitd long',
                },
              }}
              inputProps={{
                ...register('mobile', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Email'}
              justifyContent='flex-start'
              className='mb-6'
              labelProps={{ color: 'black' }}
            />
            <FormInput
              name='email'
              type='text'
              placeholder={`${'EMAIL'}...`}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the email',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              inputProps={{
                ...register('email', {}),
                // onChange: handleEmailChange,
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Secondery Mobile number'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='secondary_mobile'
              type='text'
              placeholder='Mobile..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                maxLength: {
                  value: 10,
                  message: 'Mobile Number should not exceed 10 digits',
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Only numeric characters allowed',
                },
                minLength: {
                  value: 10,
                  message: 'Mobile Number should exceed atleast 10 digitd long',
                },
              }}
              inputProps={{
                ...register('secondary_mobile', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Personal Email'}
              justifyContent='flex-start'
              className='mb-6'
              labelProps={{ color: 'black' }}
            />
            <FormInput
              name='personal_email'
              type='text'
              placeholder={`${'EMAIL'}...`}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              inputProps={{
                ...register('personal_email', {}),
                // onChange: handleEmailChange,
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'DOB'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dob'
              type='date'
              placeholder='dob..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please select date',
                },
              }}
              readOnly={view}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Gender'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={gender}
              ClassName='form-select'
              placeholder={'gender'}
              inputProps={register('gender_type', {
                required: {
                  value: true,
                  message: 'select the gender',
                },
              })}
              name={'gender_type'}
              error={errors}
              readOnly={view}
              defaultValue={location.state.data?.data?.gender_type}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Role'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={Role}
              ClassName='form-select'
              placeholder={'Role'}
              inputProps={register('role_id', {
                required: {
                  value: true,
                  message: 'select the Role',
                },
              })}
              name={'role_id'}
              error={errors}
              readOnly={view}
              defaultValue={location.state.data?.data?.role_id}
            />
            <>
              {' '}
              {console.log(
                'location.state.data?.data?.role_id',
                location.state.data?.data?.role_id
              )}
            </>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Branch'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={Role}
              ClassName='form-select'
              placeholder={'Branch'}
              inputProps={register('bunk_details_id', {
                required: {
                  value: true,
                  message: 'select the Branch',
                },
              })}
              name={'bunk_details_id'}
              error={errors}
              readOnly={view}
              defaultValue={location.state.data?.data?.bunk_details_id}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Address'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='address'
              type='text'
              placeholder='Address..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a Address',
                },
              }}
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'City'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='city'
              type='text'
              placeholder='City..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a City',
                },
              }}
              inputProps={{
                ...register('city', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'State'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='state'
              type='text'
              placeholder='State..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the state',
                },
              }}
              inputProps={{
                ...register('state', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Country'}
              justifyContent='flex-start'
              className='mb-6'
              labelProps={{ color: 'black' }}
            />
            <FormInput
              name='country'
              type='text'
              placeholder={`${'Country'}...`}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the country',
                },
              }}
              inputProps={{
                ...register('country', {}),
                // onChange: handleEmailChange,
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'PinCode'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='pincode'
              type='text'
              placeholder='Mobile..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the pincode',
                },
                maxLength: {
                  value: 6,
                  message: 'pincode should not exceed 6 digits',
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Only numeric characters allowed',
                },
                minLength: {
                  value: 6,
                  message: 'pincode should exceed atleast 6 digitd long',
                },
              }}
              inputProps={{
                ...register('pincode', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Password'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              placeholder={`Enter your Password.........`}
              className='username-input'
              name='password'
              type='password'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a password',
                },
              }}
              inputProps={{
                className: 'text-center',
                ...register('password', {}),
              }}
              readOnly={view}
              errors={errors}
            />
          </Grid>
          {view ? null : (
            <Grid
              item
              xs={12}
              justifyContent='flex-end'
              display={'flex'}
              my={5}
            >
              {reset && (
                <CustomButton
                  type='button'
                  text='reset'
                  // startIcon={ArrowBackIcon}
                  color={'primary'}
                  className='p-12 mx-10 color-gray'
                  onClick={() => {
                    reset();
                  }}
                />
              )}

              <CustomButton
                type='submit'
                // startIcon={AddIcon}
                text='Submit'
                color={'secondary'}
                className='p-12'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default EmployeeFrom;
