import { Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import Table from '../../../common/Table';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { APP_ROUTES } from '../../../common/constants/Routes';
import FormCheckBox from '../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import { modules } from '../../../common/constants/ModuleList';
import { handleCheckboxChange } from '../../../common/utils';
const RoleEdit = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const locations = useLocation();

  const roleName = locations.state?.role;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    // defaultValues:{}
  });
  const [formData, setFormData] = useState([...modules]);

  const columns: GridColDef[] = [
    {
      field: 'module',
      headerName: 'Module Access',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'add',
      headerName: 'ADD',
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ({ row }: any) => (
        <FormCheckBox
          label
          name={`${row?.module}.add`}
          checked={row?.add}
          onChange={(e) =>
            setFormData(
              handleCheckboxChange(
                row.module,
                'add',
                e.target.checked,
                formData
              )
            )
          }
        />
      ),
    },
    {
      field: 'view',
      headerName: 'View',
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ({ row }: any) => (
        <FormCheckBox
          label
          name={`${row?.module}.view`}
          checked={row?.view}
          onChange={(e) =>
            setFormData(
              handleCheckboxChange(
                row.module,
                'view',
                e.target.checked,
                formData
              )
            )
          }
        />
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: ({ row }: any) => (
        <FormCheckBox
          label
          name={`${row?.module}.edit`}
          checked={row?.edit}
          onChange={(e) =>
            setFormData(
              handleCheckboxChange(
                row.module,
                'edit',
                e.target.checked,
                formData
              )
            )
          }
        />
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: ({ row }: any) => (
        <FormCheckBox
          label
          name={`${row?.module}.delete`}
          checked={row?.delete}
          onChange={(e) =>
            setFormData(
              handleCheckboxChange(
                row.module,
                'delete',
                e.target.checked,
                formData
              )
            )
          }
        />
      ),
    },
    {
      field: 'AllAccess',
      headerName: 'All Access',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      align: 'center',
      renderCell: ({ row }: any) => (
        <FormCheckBox
          label
          name={`${row?.module}.allaccess`}
          checked={row?.allaccess}
          onChange={(e) =>
            setFormData(
              handleCheckboxChange(
                row.module,
                'allaccess',
                e.target.checked,
                formData
              )
            )
          }
        />
      ),
    },
    {
      field: 'noaccess',
      headerName: 'No Access',
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: ({ row }: any) => (
        <FormCheckBox
          label
          name={`${row?.module}.noaccess`}
          checked={row?.noaccess}
          onChange={(e) =>
            setFormData(
              handleCheckboxChange(
                row.module,
                'noaccess',
                e.target.checked,
                formData
              )
            )
          }
        />
      ),
    },
  ];

  const onSubmit = (data: any) => {
    console.log('data', data, formData);
    navigate(APP_ROUTES.ROLE);
  };
  return (
    <div>
      <Grid
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
        xs={12}
        my={2}
      >
        <Typography
          fontSize={20}
          fontWeight={600}
          color={theme.palette.info.dark}
          display={'flex'}
          my={{ xs: 1, sm: 0, md: 0 }}
        >
          Edit Role :&nbsp;{' '}
          <Typography fontSize={20} fontWeight={600}>
            {' '}
            {roleName || 'ROLE NAME'}
          </Typography>
        </Typography>

        <CustomButton
          type='button'
          text='Go Back'
          startIcon={SubdirectoryArrowRightIcon}
          color={'primary'}
          className='p-8 color-gray'
          onClick={() => {
            navigate(-1);
          }}
        />
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container xs={4}>
          <LabelWithIcon
            label={'Role Name'}
            justifyContent='flex-start'
            className='mb-6'
            labelProps={{ fontSize: 18, fontWeight: 600 }}
          />
          <FormInput
            name='role'
            type='text'
            placeholder='First Name..'
            control={control}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a First Name',
              },
            }}
            inputProps={{
              ...register('role', {}),
            }}
            errors={errors}
            defaultValue={roleName}
          />
        </Grid>
        <Grid xs={12} my={5}>
          <Table
            columns={columns}
            rows={formData}
            hideFooterPagination
            type={'dashboard'}
          />
        </Grid>
        <Grid xs={12} my={5} justifyContent={'flex-end'} display={'flex'}>
          <CustomButton
            type='submit'
            text='Save Role'
            color={'warning'}
            className='p-12'
          />
        </Grid>
      </form>
    </div>
  );
};

export default RoleEdit;
