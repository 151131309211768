import { Grid } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';

import { useLocation } from 'react-router-dom';

function CustomerForm({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
}) {
  const location = useLocation();
  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='center'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Customer Name'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='customer_name'
              type='text'
              placeholder='Name..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a Name',
                },
              }}
              inputProps={{
                ...register('customer_name', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Mobile number'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='contact'
              type='text'
              placeholder='Mobile..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the mobile',
                },
                maxLength: {
                  value: 10,
                  message: 'Mobile Number should not exceed 10 digits',
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Only numeric characters allowed',
                },
                minLength: {
                  value: 10,
                  message: 'Mobile Number should exceed atleast 10 digitd long',
                },
              }}
              inputProps={{
                ...register('contact', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Email'}
              justifyContent='flex-start'
              className='mb-6'
              labelProps={{ color: 'black' }}
            />
            <FormInput
              name='email'
              type='text'
              placeholder={`${'EMAIL'}...`}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the email',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              inputProps={{
                ...register('email', {}),
                // onChange: handleEmailChange,
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Petrol Bunk Id'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='petrol_bunk_id'
              type='text'
              placeholder='Bunk Id ...'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter bunk id',
                },
              }}
              inputProps={{
                ...register('petrol_bunk_id', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Limit'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='limit'
              type='number'
              placeholder='Limit...'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter limit',
                },
              }}
              inputProps={{
                ...register('limit', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Due Amount'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='due_amount'
              type='number'
              placeholder='Due Amount...'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter due amount',
                },
              }}
              inputProps={{
                ...register('due_amount', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Paid'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='paid'
              type='number'
              placeholder='Paid...'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter paid amount',
                },
              }}
              inputProps={{
                ...register('paid', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}></Grid>

          {view ? null : (
            <Grid
              item
              xs={12}
              justifyContent='flex-end'
              display={'flex'}
              my={5}
            >
              {reset && (
                <CustomButton
                  type='button'
                  text='reset'
                  // startIcon={ArrowBackIcon}
                  color={'primary'}
                  className='p-12 mx-10 color-gray'
                  onClick={() => {
                    reset();
                  }}
                />
              )}

              <CustomButton
                type='submit'
                // startIcon={AddIcon}
                text='Submit'
                color={'secondary'}
                className='p-12'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default CustomerForm;
