import { Grid } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { useTranslation } from 'react-i18next';

function DipFrom({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
  watch,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
  watch: any;
}) {
  const { t } = useTranslation();
  const tank: any[] = [
    {
      id: '1',
      branch: 'Elango',
      bunk_details_id: 1,
      tank_capacity: '10000',
      available_fuel: '5000',
      tank_type: 1,
      status: 1,
      tank_serai_no: 'PEOO1',
    },
    {
      id: '2',
      branch: 'Elango',
      bunk_details_id: 1,
      tank_capacity: '10000',
      available_fuel: '5000',
      tank_type: 1,
      status: 1,
      tank_serai_no: 'PE002',
    },
    {
      id: '6',
      branch: 'Elango',
      bunk_details_id: 1,
      status: 1,
      tank_capacity: '10000',
      available_fuel: '5000',
      tank_type: 2,
      tank_serai_no: 'DI001',
    },
    {
      id: '3',
      branch: 'Elango',
      bunk_details_id: 1,
      status: 1,
      tank_capacity: '10000',
      available_fuel: '5000',
      tank_type: 3,
      tank_serai_no: 'PPE001',
    },
    {
      id: '4',
      branch: 'Elango',
      bunk_details_id: 1,
      status: 1,
      tank_capacity: '10000',
      available_fuel: '5000',
      tank_type: 4,
      tank_serai_no: 'PDI001',
    },
  ];
  const user: any[] = [
    {
      personal_email: 'syt@gmail.com',
      emp_reg_no: 'GF-002',
      secondary_mobile: '33232',
      gender_type: 'M',
      dob: '2001-11-11',
      address: '3232',
      city: 'NAMAKKAL',
      state: 'TMAILNADU',
      country: 'INDIA',
      id: '1',
      first_name: 'surya',
      last_name: 'vishu',
      email: 'surya.v@yaacreations.com',
      mobile: '989877293',
      auth_code: null,
      bunk_id: null,
      bunk_details_id: null,
      user_type: 1,
      status: 1,
      created_at: '2024-08-07T13:50:27.000Z',
      updated_at: '2024-08-07T13:50:27.000Z',
      deleted_at: null,
      userProfiles: [
        {
          id: '75eb3f15-43ef-4a91-aeb8-5d9e58d1bca5',
          personal_email: 'syt@gmail.com',
          emp_reg_no: 'GF-002',
          secondary_mobile: '33232',
          gender_type: 'M',
          dob: '2001-11-11',
          user_id: 'a96907cd-1072-40c8-977e-9f8140962f35',
          role_id: '4453b11b-7de3-42dc-9b8a-902af97fe4e3',
          address: '3232',
          city: null,
          state: '32d',
          country: '33',
          profile_url: '',
          employee_type: 0,
        },
      ],
    },
    {
      id: '2',
      first_name: 'surya',
      last_name: 'vishu',
      email: 'surya22.v@yaacreations.com',
      mobile: '989877243',
      auth_code: null,
      bunk_id: null,
      bunk_details_id: null,
      user_type: 1,
      status: 1,
      created_at: '2024-08-07T13:49:54.000Z',
      updated_at: '2024-08-07T13:49:54.000Z',
      deleted_at: null,
      personal_email: 'syt@gmail.com',
      emp_reg_no: 'GF-002',
      secondary_mobile: '33232',
      gender_type: 'M',
      dob: '2001-11-11',
      address: '3232',
      city: 'NAMAKAAL',
      state: 'TMAILNADU',
      country: 'INDIA',
      userProfiles: [
        {
          id: '9352fb19-ca86-470a-8f30-b0acf7a6d921',
          personal_email: 'syt@gmail.com',
          emp_reg_no: 'GF-001',
          secondary_mobile: '33232',
          gender_type: 'M',
          dob: '2001-11-11',
          user_id: '6b2e5c12-d322-4a2e-804e-4cc19186f099',
          role_id: null,
          address: '3232',
          city: null,
          state: '32d',
          country: '33',
          profile_url: '',
          employee_type: 0,
        },
      ],
    },
    {
      id: '3',
      emp_reg_no: 'GF-003',

      address: '3/82D ,Kurinji Nagar, Namakkal',
      bunk_details_id: '1',
      city: 'NAMAKKAL',
      country: 'India',
      dob: '1998-09-05',
      email: 'arunkumar@gmail.com',
      first_name: 'keerthi',
      gender_type: 'M',
      last_name: 'v',
      mobile: '8220894345',
      password: 'KERTHI@',
      personal_email: '',
      pincode: '895584',
      role_id: '1',
      secondary_mobile: '',
      state: 'TN',
    },
    {
      id: '4',
      personal_email: 'syt@gmail.com',
      emp_reg_no: 'GF-001',
      secondary_mobile: '33232',
      gender_type: 'M',
      dob: '2001-11-11',
      user_id: 'd113facd-c767-48d7-b959-c8739b2460f1',
      role_id: '2',
      address: '3232',
      city: null,
      state: '32d',
      country: '33',
      profile_url: '',
      employee_type: 0,
      role_name: 'manager',
      first_name: 'surya',
      last_name: 'vishu',
      email: 'surya.v@yaacreations.com',
      mobile: '98987726',
    },
  ];
  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TANK')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={tank.map((data) => {
                return { Code: data.id, Description: data.tank_serai_no };
              })}
              ClassName='form-select'
              placeholder={t('TANK')}
              inputProps={register('tank_id', {
                required: {
                  value: true,
                  message: t('SELECT_THE_TANK_TYPE'),
                },
              })}
              name={'tank_id'}
              error={errors}
              readOnly={view}
              value={watch('tank_id')}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('DATE')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='entry_date'
              type='datetime-local'
              placeholder='DATE..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_SELECT_DATE'),
                },
              }}
              readOnly={view}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('DIPREADING')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dip_reading'
              type='text'
              placeholder={t('DIPREADING')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_THE_DIP_READING'),
                },

                // pattern: {
                //   value: /^[0-9]*$/,
                //   message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                // },
                // minLength: {
                //   value: 1,
                //   message: t('CAPACITY_SHOULD_EXCEES_ATLEAST_1_DIGITD_LONG'),
                // },
              }}
              inputProps={{
                ...register('dip_reading', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TANK_FILLED')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='tank_stock'
              type='text'
              placeholder={t('TANK_FILLED')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_THE_TANK_FILLED'),
                },

                pattern: {
                  value: /^[0-9]*$/,
                  message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                },
                minLength: {
                  value: 1,
                  message: t(
                    'FILLED_CAPACITY_SHOULD_EXCEES_ATLEAST_1_DIGITD_LONG'
                  ),
                },
              }}
              inputProps={{
                ...register('tank_stock', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TANK_EMPTY')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='ullage'
              type='text'
              placeholder={t('TANK_EMPTY')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_THE_TANK_EMPTY'),
                },

                pattern: {
                  value: /^[0-9]*$/,
                  message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                },
              }}
              inputProps={{
                ...register('ullage', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('READING_PERSON')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={user.map((data) => {
                return { Code: data.id, Description: data.emp_reg_no };
              })}
              ClassName='form-select'
              placeholder={t('READING_PERSON')}
              inputProps={register('user_id', {
                required: {
                  value: true,
                  message: t('SELECT_THE_READING_PERSON'),
                },
              })}
              name={'user_id'}
              error={errors}
              readOnly={view}
              value={watch('user_id')}
            />
          </Grid>
          {view ? null : (
            <Grid
              item
              xs={12}
              justifyContent='flex-end'
              display={'flex'}
              my={5}
            >
              {reset && (
                <CustomButton
                  type='button'
                  text={t('RESET')}
                  // startIcon={ArrowBackIcon}
                  color={'primary'}
                  className='p-12 mx-10 color-gray'
                  onClick={() => {
                    reset();
                  }}
                />
              )}

              <CustomButton
                type='submit'
                // startIcon={AddIcon}
                text={t('SUBMIT')}
                color={'secondary'}
                className='p-12'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default DipFrom;
