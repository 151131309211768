import { Grid } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import { useTranslation } from 'react-i18next';
import BunkForm from './BunkForm';

function ViewBunk() {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      dealer: location.state.data?.data?.dealer || '',
      dealer_name: location.state.data?.data?.dealer_name || '',
      dealer_contact: location.state.data?.data?.dealer_contact || '',
      dealer_email: location.state.data?.data?.dealer_email || '',
      secondary_mobile: location.state.data?.data?.secondary_mobile || '',
      address: location.state.data?.data?.address || '',
      city: location.state.data?.data?.city || '',
      state: location.state.data?.data?.state || '',
      country: location.state.data?.data?.country || '',
    },
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.BUNK);
  };
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('VIEW_BUNK')}
          route={APP_ROUTES.BUNK}
          buttonName={t('GO_BACK')}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid xs={12}>
        <BunkForm
          view={true}
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}
export default ViewBunk;
