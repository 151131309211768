import { Grid } from '@mui/material';
import React from 'react';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import { status } from '../../../common/constants/dropDownList';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
function Bunk() {
  const { t } = useTranslation();
  const loading = false;
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      headerName: t('ID'),
      field: 'bunk_serai_no',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('COMPANY_NAME'),
      field: 'dealer',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('DEALER_NAME'),
      field: 'dealer_name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DEALER_CONTACT'),
      field: 'dealer_contact',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DEALER_EMAIL'),
      field: 'dealer_email',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('SECONDARY_MOBILE'),
      field: 'secondary_mobile',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('ADDRESS'),
      field: 'address',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('CITY'),
      field: 'city',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('STATE'),
      field: 'state',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('COUNTRY'),
      field: 'country',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        status.find((data: any) => Number(data.Code) === Number(row?.status))
          ?.Category,
    },

    {
      headerName: t('VALID_UPTO'),
      field: 'valid_upto',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DUE'),
      field: 'due',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('ACTION'),
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.BUNKEDIT, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.BUNKVIEW, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        return (
          <>
            {' '}
            <CustomButton
              text={t('VIEW')}
              className='mx-3'
              color={'secondary'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={t('EDIT')}
              className='mx-3 '
              onClick={handleEditClick}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      id: '1',
      status: 1,
      bunk_serai_no: 'PEOO1',
      dealer: 'Elango',
      dealer_name: 'Elango',
      dealer_contact: '8220894345',
      dealer_email: 'elango@gmail.com',
      secondary_mobile: '',
      address: '1/120a, pk street',
      city: 'rasipuram',
      country: 'India',
      state: 'TamilNadu',
      valid_upto: '2024-08-18',
      due: '4000',
    },
    {
      id: 'a96907cd-1072-40c8-977e-9f8140962f35',
      status: 1,
      bunk_serai_no: 'PEOO2',
      dealer: 'Elango',
      dealer_name: 'Elango',
      dealer_contact: '8220894345',
      dealer_email: 'elango@gmail.com',
      secondary_mobile: '',
      address: '1/120a, pk street',
      city: 'rasipuram',
      country: 'India',
      state: 'TamilNadu',
      valid_upto: '2024-08-18',
      due: '8000',
    },
    {
      id: '6b2e5c12-d322-4a2e-804e-4cc19186f099',
      status: 1,
      bunk_serai_no: 'PEOO3',
      dealer: 'Elango',
      dealer_name: 'Elango',
      dealer_contact: '8220894345',
      dealer_email: 'elango@gmail.com',
      secondary_mobile: '',
      address: '1/120a, pk street',
      city: 'rasipuram',
      country: 'India',
      state: 'TamilNadu',
      valid_upto: '2024-08-18',
      due: '0',
    },
    {
      id: 4,
      dealer: 'afsd',
      dealer_name: 'fdasdf',
      dealer_contact: '9874563210',
      dealer_email: 'fklasjdfk@gmai.com',
      secondary_mobile: '9876543235',
      address: '3/82D ,Kurinji Nagar, Namakkal',
      city: 'NAMAKKAL',
      state: 'TN',
      country: 'India',
    },
  ];
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('BUNK')}
          buttonName={t('ADD_BUNK')}
          route={APP_ROUTES.BUNKADD}
          buttonIcon={AddIcon}
        />
      </Grid>
      {loading ? (
        <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
          <CircularIndeterminate />
        </Grid>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default Bunk;
