import { Grid } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import EmployeeFrom from './EmployeeFrom';

function AddEmployee() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.EMPLOYEE);
  };
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={'Add Employee'}
          route={APP_ROUTES.EMPLOYEE}
          buttonName={'Go Back'}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid>
        <EmployeeFrom
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}

export default AddEmployee;
