/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CheckboxProps {
  checked: boolean;
  label: string | any;
  CheckBoxClassName?: string | undefined;
  onClick?: any;
  name?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: number;
  checkBoxPadding?: number;
  sx?: any;
  inputProps?: any;
}

const FormCheckBox: React.FC<CheckboxProps> = ({
  checked = false,
  label,
  onChange,
  onClick,
  CheckBoxClassName,
  size = 24,
  checkBoxPadding,
  sx,
  name,
  inputProps,
}) => {
  const checkboxStyle = {
    transform: `scale(${size / 24})`,
    padding: checkBoxPadding,
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          // sx={sx}
          checked={checked}
          onChange={onChange}
          // onClick={onClick}
          // className={`${CheckBoxClassName}`}
          // style={checkboxStyle}
          name={name}
          // inputProps={inputProps}
          // value={'yes'}
        />
      }
      label={label}
    />
  );
};

export default FormCheckBox;
