export const getErrorMessageFromAPI = ({ response }: any) => {
  if (response?.data?.message) {
    return response?.data?.message;
  } else if (response?.data?.errorFields) {
    return Object.keys(response?.data?.errorFields).length === 0
      ? 'Kindly check later'
      : response?.data?.errorFields;
  } else {
    return response?.data;
  }
};

export const getStatusFromAPI = ({ response }: any) => {
  if (response?.data?.status === 'pending') {
    console.log('Pending');
  } else if (response?.data?.status === '200') {
    console.log('Response');
  }
};

export const getTokenfromLocalStorage = () => {
  return localStorage.getItem('user_id');
};
export function convertDateFormat(originalDate: any) {
  // Check if originalDate is empty string or null
  if (!originalDate) {
    return '-';
  }

  // Parse original date string into a Date object
  const dateObj = new Date(originalDate);

  // Extract day, month, and year components
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1; // January is 0 in JavaScript
  const year = dateObj.getFullYear();

  // Format the date components into the desired format (DD-MM-YYYY)
  const formattedDate = `${day < 10 ? '0' : ''}${day}-${
    month < 10 ? '0' : ''
  }${month}-${year}`;

  return formattedDate;
}

export function convertToYYYYMMDD(originalDate: any) {
  // Split the original date string by '-'
  const parts = originalDate.split('-');

  // Rearrange the parts to form the YYYY-MM-DD format
  const yyyy_mm_dd = `${parts[2]}-${parts[1]}-${parts[0]}`;

  return yyyy_mm_dd;
}
export const convertToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
export const exportToCsv = (csvData: string, fileName: string) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const stripBase64Prefix = (base64: string) => base64.split(',')[1];
export const appendBase64PrefixForImage = (base64: string) =>
  `data:image/png/jpg/jpeg;base64,${base64}`;

export const handleCheckboxChange = (
  module: any,
  type: any,
  checked: any,
  formData: any
) => {
  let updatedFormData = formData.map((item: any) => {
    if (item.module === module) {
      let newAllAccess = false;
      let newNoAccess = false;

      let addChecked = item.add;
      let viewChecked = item.view;
      let editChecked = item.edit;
      let deleteChecked = item.delete;

      if (type === 'allaccess' && checked) {
        addChecked = true;
        viewChecked = true;
        editChecked = true;
        deleteChecked = true;
        newAllAccess = true;
      } else if (type === 'noaccess' && checked) {
        addChecked = false;
        viewChecked = false;
        editChecked = false;
        deleteChecked = false;
        newAllAccess = false;
        newNoAccess = true;
      } else {
        addChecked = type === 'add' ? checked : addChecked;
        viewChecked = type === 'view' ? checked : viewChecked;
        editChecked = type === 'edit' ? checked : editChecked;
        deleteChecked = type === 'delete' ? checked : deleteChecked;

        if (addChecked && viewChecked && editChecked && deleteChecked) {
          newAllAccess = true;
        }
        if (!addChecked && !viewChecked && !editChecked && !deleteChecked) {
          newNoAccess = true;
        }
      }
      return {
        ...item,
        add: addChecked,
        view: viewChecked,
        edit: editChecked,
        delete: deleteChecked,
        allaccess: newAllAccess,
        noaccess: newNoAccess,
      };
    }
    return item;
  });

  return updatedFormData;
};

export interface AuthUser {
  mobile: string;
  email: string;
}
