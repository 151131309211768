import { Grid } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';

function BranchForm({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
}) {
  return (
    <div>
      {' '}
      <Grid container display='flex' justifyContent='center'>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'Name'}
            justifyContent='flex-start'
            className='mb-6  '
          />
          <FormInput
            name='name'
            type='text'
            placeholder='Name..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a Name',
              },
            }}
            inputProps={{
              ...register('name', {}),
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'Mobile number'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            name='contact'
            type='text'
            placeholder='Mobile..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter the mobile',
              },
              maxLength: {
                value: 10,
                message: 'Mobile Number should not exceed 10 digits',
              },
              pattern: {
                value: /^[0-9]*$/,
                message: 'Only numeric characters allowed',
              },
              minLength: {
                value: 10,
                message: 'Mobile Number should exceed atleast 10 digitd long',
              },
            }}
            inputProps={{
              ...register('contact', {}),
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'MS Tank'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            name='ms_tank'
            type='text'
            placeholder='MS Tank..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a MS Tank',
              },
              pattern: {
                value: /^[0-9]*$/,
                message: 'Only numeric characters allowed',
              },
            }}
            inputProps={{
              ...register('ms_tank', {}),
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'HSD Tank'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            name='hsd_tank'
            type='text'
            placeholder='HSD Tank..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a HSD Tank',
              },
              pattern: {
                value: /^[0-9]*$/,
                message: 'Only numeric characters allowed',
              },
            }}
            inputProps={{
              ...register('hsd_tank', {}),
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'HSD Dispenser Count'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            name='hsd_dispenser_count'
            type='text'
            placeholder='HSD Dispenser Count..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a HSD Dispenser Count',
              },
              pattern: {
                value: /^[0-9]*$/,
                message: 'Only numeric characters allowed',
              },
            }}
            inputProps={{
              ...register('hsd_dispenser_count', {}),
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'HSD Power Dispenser Count'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            name='hsd_power_dispenser'
            type='text'
            placeholder='HSD Power Dispenser Count..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a HSD Power Dispenser Count',
              },
              pattern: {
                value: /^[0-9]*$/,
                message: 'Only numeric characters allowed',
              },
            }}
            inputProps={{
              ...register('hsd_power_dispenser', {}),
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'MS Dispenser Count'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            name='ms_dispenser_count'
            type='text'
            placeholder='MS Dispenser Count..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a MS Dispenser Count',
              },
              pattern: {
                value: /^[0-9]*$/,
                message: 'Only numeric characters allowed',
              },
            }}
            inputProps={{
              ...register('ms_dispenser_count', {}),
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'MS Power Dispenser Count'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            name='ms_power_dispenser'
            type='text'
            placeholder='MS Power Dispenser Count..'
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter a MS Power Disepnser Count',
              },
              pattern: {
                value: /^[0-9]*$/,
                message: 'Only numeric characters allowed',
              },
            }}
            inputProps={{
              ...register('ms_power_dispenser', {}),
            }}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'Email'}
            justifyContent='flex-start'
            className='mb-6'
            labelProps={{ color: 'black' }}
          />
          <FormInput
            name='email'
            type='text'
            placeholder={`${'EMAIL'}...`}
            control={control}
            readOnly={view}
            className='input-from'
            rules={{
              required: {
                value: true,
                message: 'Please enter the email',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            inputProps={{
              ...register('email', {}),
              // onChange: handleEmailChange,
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={'Address'}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <FormInput
            hideCountButton
            multipleLine
            name='address'
            type='text'
            placeholder='Address..'
            control={control}
            readOnly={view}
            className='input-from-text'
            rules={{
              required: {
                value: true,
                message: 'Please enter a address',
              },
            }}
            inputProps={{
              ...register('address', {}),
            }}
            errors={errors}
          />
        </Grid>
        {/* {view ? null : (
          <Grid item xs={12} justifyContent='flex-end' display={'flex'} my={5}>
            {reset && (
              <CustomButton
                type='button'
                text='reset'
                // startIcon={ArrowBackIcon}
                color={'primary'}
                className='p-12 mx-10 color-gray'
                onClick={() => {
                  reset();
                }}
              />
            )}

            <CustomButton
              type='submit'
              // startIcon={AddIcon}
              text='Submit'
              color={'secondary'}
              className='p-12'
            />
          </Grid>
        )} */}
      </Grid>
    </div>
  );
}

export default BranchForm;
