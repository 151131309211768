import React from 'react';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { Grid, Typography, useTheme } from '@mui/material';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { fuelType } from '../../../common/constants/dropDownList';
import Separator from '../../../common/ui/Separator';
import { useTranslation } from 'react-i18next';
import PdfUpload from '../../../common/PdfUpload';
const NozzleFrom = ({
  control,
  errors,
  index,
  register,
  view,
  watch,
  setValue,
}: {
  control: any;
  errors: any;
  index: any;
  register: any;
  remove: any;
  view: any;
  watch: any;
  setValue: any;
}) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} p={2}>
        <Separator color={theme.palette.warning.dark} />
      </Grid>
      <Grid item xs={12} p={2}>
        <Typography color={theme.palette.info.dark} fontSize={18}>
          {t('NOZZLE')}-{index + 1}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={t('NOZZLE_TYPE')}
          justifyContent='flex-start'
          className='mb-6 '
        />
        <CustomSelectBox
          options={fuelType}
          ClassName='form-select'
          placeholder={t('NOZZLE_TYPE')}
          inputProps={register(`dispenser_details.[${index}].nozzle_type`, {
            required: {
              value: true,
              message: t('SELECT_THE_DISPENSER_TYPE'),
            },
          })}
          name={`nozzle_type`}
          error={errors?.dispenser_details?.[index]}
          readOnly={view}
          value={watch(`dispenser_details.[${index}].nozzle_type`)}
        />
      </Grid>
      {view && (
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={t('VALID_UPTO')}
            justifyContent='flex-start'
            className='mb-6 ml-3'
          />
          <FormInput
            name={`dispenser_details.[${index}].valid_upto`}
            type='date'
            placeholder='valid_upto..'
            control={control}
            className='input-from'
            inputProps={{
              ...register(`dispenser_details.[${index}].valid_upto` as const),
            }}
            rules={{
              required: {
                value: true,
                message: t('SELECT_THE_VALID_UPTO'),
              },
            }}
            errorName={'valid_upto'}
            errors={errors?.dispenser_details?.[index]}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={t('CALLIBARATION_FACTOR')}
          justifyContent='flex-start'
          className='mb-6 '
        />
        <FormInput
          name={`dispenser_details.[${index}].callibration_factor`}
          type='number'
          placeholder={t('CALLIBARATION_FACTOR')}
          control={control}
          readOnly={view}
          className='input-from'
          rules={{
            required: {
              value: true,
              message: t('PLEASE_ENTER_CALLIBARATION_FACTOR'),
            },
            max: {
              value: 1.05,
              message: t('CALIBRATION_FACTOR_SHOULD_EXCEED'),
            },
            min: {
              value: 0.95,
              message: t('CALIBRATION_FACTOR_SHOULD_LEAST'),
            },
          }}
          inputProps={{
            ...register(`dispenser_details.[${index}].callibration_factor`, {}),
            step: 0.001,
          }}
          errorName={'callibration_factor'}
          errors={errors?.dispenser_details?.[index]}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={t('W&M_CERTIFICATE_NO')}
          justifyContent='flex-start'
          className='mb-6 '
        />
        <FormInput
          name={`dispenser_details.[${index}].wm_certificate_no`}
          type='text'
          placeholder={t('W&M_CERTIFICATE_NO')}
          control={control}
          readOnly={view}
          className='input-from'
          rules={{
            required: {
              value: true,
              message: t('PLEASE_ENTER_W&M_CERTIFICATE_NO'),
            },
          }}
          inputProps={{
            ...register(`dispenser_details.[${index}].wm_certificate_no`, {}),
          }}
          errorName={'wm_certificate_no'}
          errors={errors?.dispenser_details?.[index]}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={t('W&M_DATE')}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <FormInput
          name={`dispenser_details.[${index}].wm_date`}
          type='date'
          placeholder='vm_date..'
          control={control}
          className='input-from'
          inputProps={{
            ...register(`dispenser_details.[${index}].wm_date` as const),
          }}
          rules={{
            required: {
              value: true,
              message: t('SELECT_THE_W&M_DATE'),
            },
          }}
          errorName={'wm_date'}
          errors={errors?.dispenser_details?.[index]}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <PdfUpload
          control={control}
          register={register}
          errors={errors?.dispenser_details?.[index]}
          watch={watch}
          setValue={setValue}
          name={`dispenser_details.[${index}].wm_certificate`}
          secondName={`dispenser_details.[${index}].wm_certificate`}
          errorName={'wm_certificate'}
          labelName={t('wm_certificate')}
          errorMessage={t('PLEASER_SELECT_THE_W_M_CERTIFICATE')}
        />
      </Grid>
    </>
  );
};

export default NozzleFrom;
