import { Grid } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
// import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
// import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function BunkForm({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('COMPANY_NAME')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dealer'
              type='text'
              placeholder={t('COMPANY_NAME')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_THE_COMPANY_NAME'),
                },
              }}
              inputProps={{
                ...register('dealer', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('DEALER_NAME')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dealer_name'
              type='text'
              placeholder={t('DEALER_NAME')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_THE_DEALER_NAME'),
                },
              }}
              inputProps={{
                ...register('dealer_name', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('DEALER_CONTACT')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dealer_contact'
              type='text'
              placeholder={t('DEALER_CONTACT')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_MOBILE_NUMBER'),
                },
                maxLength: {
                  value: 10,
                  message: t('MOBILE_NUMBER_SHOULD_NOT_EXCEED'),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                },
                minLength: {
                  value: 10,
                  message: t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'),
                },
              }}
              inputProps={{
                ...register('dealer_contact', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('DEALER_EMAIL')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='dealer_email'
              type='text'
              placeholder={t('DEALER_EMAIL')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_EMAIL'),
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('INVALID_EMAIL_ADDRESS'),
                },
              }}
              inputProps={{
                ...register('dealer_email', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('SECONDARY_MOBILE')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='secondary_mobile'
              type='text'
              placeholder={t('SECONDARY_MOBILE')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                maxLength: {
                  value: 10,
                  message: t('MOBILE_NUMBER_SHOULD_NOT_EXCEED'),
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                },
                minLength: {
                  value: 10,
                  message: t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'),
                },
              }}
              inputProps={{
                ...register('secondary_mobile', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Address'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='address'
              type='text'
              placeholder='Address..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a Address',
                },
              }}
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'City'}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='city'
              type='text'
              placeholder='City..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a City',
                },
              }}
              inputProps={{
                ...register('city', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'State'}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='state'
              type='text'
              placeholder='State..'
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the state',
                },
              }}
              inputProps={{
                ...register('state', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Country'}
              justifyContent='flex-start'
              className='mb-6'
              labelProps={{ color: 'black' }}
            />
            <FormInput
              name='country'
              type='text'
              placeholder={`${'Country'}...`}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the country',
                },
              }}
              inputProps={{
                ...register('country', {}),
                // onChange: handleEmailChange,
              }}
              errors={errors}
            />
          </Grid>

          {view ? null : (
            <Grid
              item
              xs={12}
              justifyContent='flex-end'
              display={'flex'}
              my={5}
            >
              {reset && (
                <CustomButton
                  type='button'
                  text={t('RESET')}
                  // startIcon={ArrowBackIcon}
                  color={'primary'}
                  className='p-12 mx-10 color-gray'
                  onClick={() => {
                    reset();
                  }}
                />
              )}

              <CustomButton
                type='submit'
                // startIcon={AddIcon}
                text={t('SUBMIT')}
                color={'secondary'}
                className='p-12'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}
export default BunkForm;
