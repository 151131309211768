import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import React from 'react';
import BranchForm from './BranchForm';
import Peso from './Peso';
import Labor from './Labor';
import DMNOC from './DMNOC';
import Shops from './Shops';
import Lease from './Lease';
import Gst from './Gst';
import MSME from './MSME';
import Electricity from './Electricity';
import WaterConnection from './WaterConnection';
import Property from './Property';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useNavigate } from 'react-router-dom';

function BranchStep({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
  watch,
  setValue,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
  watch: any;
  setValue: any;
}) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const steps = [
    'Basic Details',
    'PESO',
    'Labor license',
    'DMNOC',
    'Shops and establishment',
    'Lease agreement',
    'Gst Certificate',
    'MSME & UDHAM',
    'Electricity bill',
    'Water connections',
    'Property tax',
  ];

  const handleNext = (data: any) => {
    console.log('data', data);
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    if (activeStep === 10) {
      navigate(APP_ROUTES.BRANCH);
    }
  };

  const handleBack = (data: any) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStep = (step: number) => () => {
  //   setActiveStep(step);
  // };

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit(handleNext)}>
        <Stepper nonLinear activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color='inherit'>{label}</StepButton>
            </Step>
          ))}
        </Stepper>

        <Typography px={2}>{steps[activeStep]}</Typography>
        {activeStep === 0 && (
          <BranchForm
            reset={reset}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            control={control}
            register={register}
            errors={errors}
            view={view}
          />
        )}
        {activeStep === 1 && (
          <Peso
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 2 && (
          <Labor
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 3 && (
          <DMNOC
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 4 && (
          <Shops
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 5 && (
          <Lease
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 6 && (
          <Gst
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 7 && (
          <MSME
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 8 && (
          <Electricity
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 9 && (
          <WaterConnection
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}
        {activeStep === 10 && (
          <Property
            control={control}
            register={register}
            errors={errors}
            view={view}
            watch={watch}
            setValue={setValue}
          />
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color='inherit'
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button sx={{ mr: 1 }} type='submit'>
            Next
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default BranchStep;
