import { Grid } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import DispenserFrom from './DispenserFrom';
import { useTranslation } from 'react-i18next';

function EditDispenser() {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      nozzle: location.state.data?.data?.nozzle || '',
      dispenser_model: location.state.data?.data?.dispenser_model || '',
      dispenser_type: location.state.data?.data?.dispenser_type || '',
      bunk_details_id: location.state.data?.data?.bunk_details_id || '',
      otp_date: location.state.data?.data?.otp_date || '',
      dispenser_maker: location.state.data?.data?.dispenser_maker || '',
    },
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.DISPENSER);
  };
  const { t } = useTranslation();
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('EDIT_DISPENSER')}
          route={APP_ROUTES.DISPENSER}
          buttonName={t('GO_BACK')}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid xs={12}>
        <DispenserFrom
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          edit={true}
        />
      </Grid>
    </Grid>
  );
}
export default EditDispenser;
