import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import CustomButton from '../../../common/ui/CustomButton';
import Table from '../../../common/Table';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
import Header from '../../../common/Header';
import { useTranslation } from 'react-i18next';
const Role = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [Succesopen, setSuccesopen] = useState(false);
  const success = () => {
    setSuccesopen(true);
  };
  const handleDelete = () => {
    setOpen(true);
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },

    {
      field: 'Action',
      headerName: 'Action',
      width: 190,
      disableColumnMenu: true,
      renderCell: ({ row }: any) => (
        <>
          <CustomButton
            text={'Edit'}
            className='mx-5 '
            color={'warning'}
            onClick={() => {
              navigate(APP_ROUTES.EDITROLE, { state: { role: row?.role } });
            }}
          />
          <CustomButton
            text={'Delete'}
            className='mx-3 color-gray'
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];
  const rows = [
    {
      id: 1,
      status: 'ACTIVE',
      role: 'Beneficiaries',
    },
    {
      id: 2,
      status: 'ACTIVE',
      role: 'Beneficiaries1',
    },
    {
      id: 3,
      status: 'ACTIVE',
      role: 'Beneficiaries1',
    },
    {
      id: 4,
      status: 'ACTIVE',
      role: 'Beneficiaries1',
    },
    {
      id: 5,
      status: 'ACTIVE',
      role: 'Beneficiaries1',
    },
    {
      id: 6,
      status: 'ACTIVE',
      role: 'Beneficiaries1',
    },
    {
      id: 7,
      status: 'ACTIVE',
      role: 'Beneficiaries1',
    },
  ];
  return (
    <Grid container>
      <Grid xs={12}>
        <Header
          view={true}
          header={t('ROLE')}
          buttonName={t('ADD_ROLE')}
          route={APP_ROUTES.ADDROLE}
          buttonIcon={AddIcon}
        />
      </Grid>

      <Grid xs={12} my={5}>
        <Table columns={columns} rows={rows} />
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} />
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
    </Grid>
  );
};

export default Role;
