export const modules = [
  {
    id: '1',
    module: 'Fuel',
    add: false,
    view: false,
    edit: false,
    delete: false,
    allaccess: false,
    noaccess: true,
  },
  {
    id: '2',
    module: 'Employee',
    add: false,
    view: false,
    edit: false,
    delete: false,
    allaccess: false,
    noaccess: true,
  },
  {
    id: '3',
    module: 'Dispenser',
    add: false,
    view: false,
    edit: false,
    delete: false,
    allaccess: false,
    noaccess: true,
  },
  {
    id: '4',
    module: 'Tank',
    add: false,
    view: false,
    edit: false,
    delete: false,
    allaccess: false,
    noaccess: true,
  },
  {
    id: '5',
    module: 'Dip reading',
    add: false,
    view: false,
    edit: false,
    delete: false,
    allaccess: false,
    noaccess: true,
  },
  {
    id: '6',
    module: 'Employee Attendances',
    add: false,
    view: false,
    edit: false,
    delete: false,
    allaccess: false,
    noaccess: true,
  },
];
