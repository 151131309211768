export const AUTH_ROUTES = {
  LOGIN: '/',
  LOGIN_OTP_VERIFY: '/login/otp-verify',
  FORGET_PASSWORD: '/forgetPassword',
};

export const APP_ROUTES = {
  DASHBOARD: '/',
  CHANGEPASSWORD: '/changepassword',
  BRANCH: '/branch',
  PROFILE: '/profile',
  EMPLOYEE: '/employee',
  ADDBRANCH: '/branch/add',
  EDITBRANCH: '/branch/edit',
  VIEWBRANCH: '/branch/view',
  ADDEMPLOYEE: '/employee/add',
  EDITEMPLOYEE: '/employee/edit',
  VIEWEMPLOYEE: '/employee/view',
  TANK: '/tank',
  ADDTANK: '/tank/add',
  EDITTANK: '/tank/edit',
  VIEWTANK: '/tank/view',
  DISPENSER: '/dispenser',
  ADDDISPENSER: '/dispenser/add',
  EDITDISPENSER: '/dispenser/edit',
  VIEWDISPENSER: '/dispenser/view',
  FUEL: '/fuel',
  ADDFUEL: '/fuel/add',
  EDITFUEL: '/fuel/edit',
  VIEWFUEL: '/fuel/view',
  ROLE: '/role',
  ADDROLE: '/role/add',
  EDITROLE: '/role/edit',
  VIEWROLE: '/role/view',
  DIP: '/dip',
  DIPADD: '/dip/add',
  DIPEDIT: '/dip/edit',
  DIPVIEW: '/dip/view',
  ATTENDANCES: '/attendances',
  ATTENDANCESADD: '/attendances/add',
  ATTENDANCESEDIT: '/attendances/edit',
  ATTENDANCESVIEW: '/attendances/view',
  CUSTOMER: '/customer',
  CUSTOMERADD: '/customer/add',
  CUSTOMEREDIT: '/customer/edit',
  BUNK: '/bunk',
  BUNKADD: '/bunk/add',
  BUNKEDIT: '/bunk/edit',
  BUNKVIEW: '/bunk/view',
};

export const PUBLIC_ROUTES = {};
