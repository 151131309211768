/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';

function DashboardCard({
  total,
  content,
  icon,
  classname,
  navaigate,
  linearValue,
}: {
  total: any;
  content: any;
  icon?: any;
  classname?: any;
  navaigate: any;
  linearValue?: any;
}) {
  const theme = useTheme();
  return (
    <Paper elevation={3}>
      <Link to={navaigate}>
        <Card sx={{ alignItems: 'center', p: 2, height: '150px' }}>
          <CardHeader
            avatar={icon}
            // action={icon}
            title={content}
            subheader={total}
            titleTypographyProps={{ fontSize: 16, fontWeight: 600 }}
            subheaderTypographyProps={{ fontSize: 16, fontWeight: 600 }}
          />
          <CardActions>
            {linearValue && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant='determinate'
                  value={50}
                  sx={{ height: 10, borderRadius: 5 }}
                />
              </Box>
            )}
          </CardActions>
        </Card>
      </Link>
    </Paper>
  );
}

export default DashboardCard;
