import InboxIcon from '@mui/icons-material/MoveToInbox';
import { APP_ROUTES } from './Routes';

const MENU: any = [
  {
    path: APP_ROUTES.DASHBOARD,
    title: 'DashBoard',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.BUNK,
    title: 'BUNK',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.BRANCH,
    title: 'Branches',
    Icon: InboxIcon,
  },

  {
    path: APP_ROUTES.EMPLOYEE,
    title: 'Employee',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.CUSTOMER,
    title: 'Customer',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.TANK,
    title: 'Tank',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.DISPENSER,
    title: 'Dispenser',
    Icon: InboxIcon,
  },

  {
    path: APP_ROUTES.FUEL,
    title: 'FUEL',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.ROLE,
    title: 'ROLE',
    Icon: InboxIcon,
  },
  {
    path: APP_ROUTES.DIP,
    title: 'DIPREADING',
    Icon: InboxIcon,
  },

  // {
  //   path: APP_ROUTES.ATTENDANCES,
  //   title: 'EMPLOYEEATTENCE',
  //   Icon: InboxIcon,
  // },
  // {
  //   path: APP_ROUTES.DASHBOARD,
  //   title: 'Reports',
  //   Icon: InboxIcon,
  // },
  { title: 'MY_PROFILE', path: APP_ROUTES.PROFILE, Icon: InboxIcon },
  {
    title: 'CHANGE_PASSWORD',
    path: APP_ROUTES.CHANGEPASSWORD,
    Icon: InboxIcon,
  },

  { title: 'LOG_OFF' },
];
export default MENU;
