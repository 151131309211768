export const gender = [
  {
    Code: 'M',
    Description: 'Male',
    Category: 'Male',
  },
  {
    Code: 'F',
    Description: 'Female',
    Category: 'Female',
  },
  {
    Code: 'O',
    Description: 'Others',
    Category: 'Others',
  },
];
export const Role = [
  {
    Code: '1',
    Description: 'EMPLOYEE',
    Category: 'Male',
  },
  {
    Code: '2',
    Description: 'ADMIN',
    Category: 'Female',
  },
];
export const status = [
  {
    Code: 1,
    Description: 'Active',
    Category: 'Active',
  },
  {
    Code: 2,
    Description: 'Active',
    Category: 'Active',
  },
];
export const bloodGroup = [
  {
    Code: 1,
    Description: 'O+',
    Category: 'O+',
  },
  {
    Code: 2,
    Description: 'O-',
    Category: 'O-',
  },
  {
    Code: 3,
    Description: 'A+',
    Category: 'A+',
  },
  {
    Code: 4,
    Description: 'A-',
    Category: 'A-',
  },
  {
    Code: 5,
    Description: 'B+',
    Category: 'B+',
  },
  {
    Code: 6,
    Description: 'B-',
    Category: 'B-',
  },
  {
    Code: 7,
    Description: 'AB+',
    Category: 'AB+',
  },
  {
    Code: 8,
    Description: 'AB-',
    Category: 'AB-',
  },
];

export const fuelType = [
  {
    Code: 1,
    Description: 'petrol (MS)',
    Category: 'petrol (MS)',
  },
  {
    Code: 2,
    Description: 'Diesel (HSD)',
    Category: 'Diesel (HSD)',
  },
  {
    Code: 3,
    Description: 'petrol (Power)',
    Category: 'petrol (Power)',
  },
  {
    Code: 4,
    Description: 'Diesel (Power)',
    Category: 'Diesel (Power)',
  },
];
export const paymentMethod: any = [
  {
    Code: 1,
    Description: 'Cash',
    Category: 'Cash',
  },
  {
    Code: 2,
    Description: 'Cheque',
    Category: 'Cheque',
  },
  {
    Code: 3,
    Description: 'Online',
    Category: 'Online',
  },
];
export const nozzleCount = [
  {
    Code: 2,
    Description: 2,
    Category: 2,
  },
  {
    Code: 4,
    Description: 4,
    Category: 4,
  },
  {
    Code: 6,
    Description: 6,
    Category: 6,
  },
  {
    Code: 8,
    Description: 8,
    Category: 8,
  },
];
// export default { bloodGroup, gender };
