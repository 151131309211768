import { Grid, useTheme ,Typography} from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import './changePassword.scss';

import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import image from '../../../common/icons/YAA PETROLEUM.png';

import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useTranslation } from 'react-i18next';


const ChangePassword = () => {
  const {t}=useTranslation()
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues
  } = useForm();
  const [passwordMatch, setPasswordMatch] = useState(true);
  const navigate =useNavigate()

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    const newPassword = getValues('newPassword');
    if (name === 'confirmPassword' && newPassword !== value) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };
  const onSubmit = (data:any) => {
  
    
   
  };
  return (
    <Grid className='change-password'>
      <Grid >
      <Grid container xs={12} p={2}>
              <Grid item xs={6} alignItems={'center'} display={'flex'}>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color={theme.palette.info.dark}
                >
                  {t('CHANGE_PASSWORD')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                alignItems={'center'}
                justifyContent={'flex-end'}
                display={'flex'}
              >
                <CustomButton
                  type='button'
                  text={`${t('Go_Back')}`}
                  startIcon={SubdirectoryArrowRightIcon}
                  color={'primary'}
                  className='p-8 color-gray'
                  onClick={() => {
                    navigate(APP_ROUTES.DASHBOARD);
                  }}
                />
              </Grid>
            </Grid>
        <Grid justifyContent='center' display='flex' my={2}>
          <img src={image} alt='logo' className='img-change-password' />
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
          
          <Grid my={1}>
              <LabelWithIcon
                label={`${t('CURRENT_PASSWORD')}`}
                labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
                justifyContent='flex-start'
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                placeholder='Current Password.........'
                className='username-input'
                name='password'
                type='password'
                control={control}
                rules={{
                  required: `${t('PLEASE_ENTER_CURRENT_PASSWORD')}`,
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('password'),
                 
                }}
                errors={errors}
              />
            </Grid>
            <Grid my={1}>
              <LabelWithIcon
                label={`${t('NEW_PASSWORD')}`}
                labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
                justifyContent='flex-start'
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                placeholder={`${t('NEW_PASSWORD')}.........`}
                className='username-input'
                name='newPassword'
                type='password'
                control={control}
                rules={{
                  required: `${t('PLEASE_ENTER_NEW_PASSWORD')}`,
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('newPassword'),
                  onChange: handleChange
                }}
                errors={errors}
              />
            </Grid>
            <Grid my={1}>
              <LabelWithIcon
                label={`${t('CONFIRM_PASSWORD')}`}
                labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
                justifyContent='flex-start'
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                placeholder={`${t('CONFIRM_PASSWORD')}.....`}
                className='username-input'
                name='confirmPassword'
                type='password'
                control={control}
                rules={{
                  required: `${t('PLEASE_CONFORM_YOUR_PASSWORD')}`,
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('confirmPassword'),
                  onChange: handleChange
                }}
                errors={errors}
              />
              {!passwordMatch && <Typography color={"red"}>{t('NEW_PASSWORD_AND CONFIRM_PASSWORD_SHOULD_BE_SAME')}</Typography>}
            </Grid>
            <Grid justifyContent='center' display='flex' my={5}>
              <CustomButton
                type='submit'
                text={`${t('SUBMIT')}`}
                width={200}
                disabled={!passwordMatch}
                color='secondary'
                className='p-10'
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
