import { Grid } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import EmployeeFrom from './EmployeeFrom';

function EditEmploye() {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      emp_reg_no: location.state.data?.data?.emp_reg_no || '',
      address: location.state.data?.data?.address || '',
      bunk_details_id: location.state.data?.data?.bunk_details_id || '',
      city: location.state.data?.data?.city || '',
      country: location.state.data?.data?.country || '',
      dob: location.state.data?.data?.dob || '',
      email: location.state.data?.data?.email || '',
      first_name: location.state.data?.data?.first_name || '',
      gender_type: location.state.data?.data?.gender_type || '',
      last_name: location.state.data?.data?.last_name || '',
      mobile: location.state.data?.data?.mobile || '',
      password: location.state.data?.data?.password || '',
      personal_email: location.state.data?.data?.personal_email || '',
      pincode: location.state.data?.data?.pincode || '',
      role_id: location.state.data?.data?.role_id || '',
      secondary_mobile: location.state.data?.data?.secondary_mobile || '',
      state: location.state.data?.data?.state || '',
    },
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.EMPLOYEE);
  };
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={'Edit Employee'}
          route={APP_ROUTES.EMPLOYEE}
          buttonName={'Go Back'}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid>
        <EmployeeFrom
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}
export default EditEmploye;
