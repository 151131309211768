import { Grid } from '@mui/material';
import React from 'react';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';

import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
function EmployeeAttendance() {
  const { t } = useTranslation();
  const loading = false;
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      headerName: t('NAME'),
      field: 'name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('START_DATE_TIME'),
      field: 'start_date_time',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('END_DATE_TIME'),
      field: 'end_date_time',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('DISPENSER_OPEN_READING'),
      field: 'dipenser_opening_reading',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('DISPENSER_CLOSE_READING'),
      field: 'dipenser_opening_reading',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('PETTY_CASH_AMOUNT'),
      field: 'petty_cash_amount',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('TOTAL_SALES'),
      field: 'total_sales',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('CASH_IN_HAND'),
      field: 'cash_in_hand',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('CREDIT'),
      field: 'credit',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('ONLINE'),
      field: 'online',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('STATUS'),
      field: 'status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('ACTION'),
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.ATTENDANCESEDIT, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.ATTENDANCESVIEW, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        return (
          <>
            {' '}
            <CustomButton
              text={t('VIEW')}
              className='mx-3'
              color={'secondary'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={t('EDIT')}
              className='mx-3 '
              onClick={handleEditClick}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      name: 'Mani',
      start_date_time: '2024-08-12T17:00',
      end_date_time: '2024-08-12T17:00',
      total_sales: '15000',
      cash_in_hand: '5000',
      petty_cash_amount: '1000',
      credit: '',
      online: '',
      status: '',
      dipes_opening_reading: '',
      dispensering_closing_reading: '',
      id: '1',
    },
    {
      id: '6b2e5c12-d322-4a2e-804e-4cc19186f099',
      branch: 'Elango',
      bunk_details_id: 1,
      nozzle: 1,
      dispenser_model: '10000',
      dispenser_type: 2,
    },
    {
      id: '3',
      branch: 'Elango',
      bunk_details_id: 1,
      nozzle: 1,
      dispenser_model: '10000',
      dispenser_type: 3,
    },
    {
      id: '4',
      branch: 'Elango',
      bunk_details_id: 1,
      nozzle: 1,
      dispenser_model: '10000',
      dispenser_type: 4,
    },
  ];
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('EMPLOYEEATTENCE')}
          buttonName={t('ADD_DISPENSER')}
          route={APP_ROUTES.ATTENDANCESADD}
          buttonIcon={AddIcon}
        />
      </Grid>
      {loading ? (
        <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
          <CircularIndeterminate />
        </Grid>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default EmployeeAttendance;
