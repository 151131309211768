import { delay, put, takeLatest, call } from 'redux-saga/effects';

import {
  fetchLoginRequest,
  fetchOtpRequest,
  fetchOtpVerify,
  forgetPasswordError,
  forgetPasswordRequest,
  forgetPasswordSuccess,
  logoutError,
  logoutRequest,
  reportLoginRequestError,
  reportLoginRequestSuccess,
  reportOtpLoginError,
  reportOtpLoginSuccess,
  reportOtpVerifyError,
  reportOtpVerifySuccess,
  signUpError,
  signUpRequest,
  signUpSuccess,
} from './_auth.slice';
import {
  submitForgetPasswordRequest,
  submitLoginRequestInfo,
  submitOtpRequest,
  submitOtpVerifyRequest,
  submitSignUpRequest,
} from './_auth.service';
import { AxiosResponse } from 'axios';

import { APP_ROUTES, AUTH_ROUTES } from '../../common/constants/Routes';
import cryptoEncryptionDecryption from '../../common/crypto';
import { setAlertInfo } from '../../common/Common.slice';
function* handleLoginRequest({
  payload,
}: ReturnType<typeof fetchLoginRequest>) {
  const { navigate, ...requestPayload }: any = payload;

  try {
    const { data }: AxiosResponse<any> = yield call(
      submitLoginRequestInfo,
      requestPayload?.data
    );
    yield put(reportLoginRequestSuccess(data));

    if (data?.user) {
      if (requestPayload.handleSuccess) {
        requestPayload.handleSuccess();
      }
      // localStorage.setItem(
      //   'userInfo',
      //   cryptoEncryptionDecryption.Encrypt(data?.user)
      // );
      // localStorage.setItem('accessToken', data?.accessToken);
      // localStorage.setItem('name', data?.user?.name);
      // localStorage.setItem('role_id', data?.user?.role_id);
    }
  } catch (e: any) {
    if (e?.response?.data?.otpVerification === 'Y') {
      navigate(AUTH_ROUTES.LOGIN_OTP_VERIFY, {
        state: { email: requestPayload?.data?.email, type: 'SignUp' },
      });
    }

    yield put(reportLoginRequestError(e.message));
  }
}

function* handleSignUpRequest({ payload }: ReturnType<typeof signUpRequest>) {
  const { navigate, ...requestPayload }: any = payload;

  try {
    const { data }: AxiosResponse<any> = yield call(
      submitSignUpRequest,
      requestPayload?.data
    );
    yield put(signUpSuccess(data));

    if (requestPayload.handleSuccess) {
      requestPayload.handleSuccess();
    }
  } catch (e: any) {
    yield put(signUpError(e.message));
  }
}
function* handleOtpSendRequest({
  payload,
}: ReturnType<typeof fetchOtpRequest>) {
  const { navigate, ...requestPayload }: any = payload;

  try {
    const { data }: AxiosResponse<any> = yield call(
      submitOtpRequest,
      requestPayload
    );
    yield put(reportOtpLoginSuccess(data));
    if (requestPayload.handleSuccess) {
      requestPayload.handleSuccess();
    }
  } catch (e: any) {
    yield put(reportOtpLoginError(e.message));
  }
}
function* handleOtpVerify({ payload }: ReturnType<typeof fetchOtpVerify>) {
  const { navigate, ...requestPayload }: any = payload;
  try {
    const { data }: AxiosResponse<any> = yield call(
      submitOtpVerifyRequest,
      requestPayload
    );
    yield put(reportOtpVerifySuccess(data));
    if (requestPayload.handleSuccess) {
      requestPayload.handleSuccess();
    }
  } catch (e: any) {
    yield put(reportOtpVerifyError(e.message));
  }
}
function* handleForgetPassword({
  payload,
}: ReturnType<typeof forgetPasswordRequest>) {
  const { navigate, ...requestPayload }: any = payload;
  console.log(requestPayload);
  try {
    const { data }: AxiosResponse<any> = yield call(
      submitForgetPasswordRequest,
      requestPayload?.data
    );
    yield put(forgetPasswordSuccess(data));
    if (requestPayload.handleSuccess) {
      requestPayload.handleSuccess();
    }
  } catch (e: any) {
    yield put(forgetPasswordError(e.message));
  }
}
function* handleUserLogout({ payload }: ReturnType<typeof logoutRequest>) {
  const { navigate, ...requestPayload }: any = payload;

  try {
    localStorage.removeItem('user');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('role_id');
  } catch (e: any) {
    yield put(logoutError(e.message));
  }
}

export default function* authSaga() {
  yield takeLatest(fetchLoginRequest.type, handleLoginRequest);
  yield takeLatest(signUpRequest.type, handleSignUpRequest);
  yield takeLatest(fetchOtpRequest.type, handleOtpSendRequest);
  yield takeLatest(fetchOtpVerify.type, handleOtpVerify);
  yield takeLatest(forgetPasswordRequest.type, handleForgetPassword);
  yield takeLatest(logoutRequest.type, handleUserLogout);
}
