import { Grid } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import TankFrom from './TankFrom';
import { useTranslation } from 'react-i18next';

function AddTank() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.TANK);
  };
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('ADD_TANK')}
          route={APP_ROUTES.TANK}
          buttonName={t('GO_BACK')}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid xs={12}>
        <TankFrom
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}

export default AddTank;
