import { Grid } from '@mui/material';
import React from 'react';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import BranchHeader from './BranchHeader';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
function Branch() {
  const loading = false;
  const navigate = useNavigate();
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: 'MS Tank',
      field: 'ms_tank',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: 'HSD Tank',
      field: 'hsd_tank',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: 'Tank Count',
      field: 'tank_count',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: 'HSD Dispenser Count',
      field: 'hsd_dispenser_count',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'hsd_power_dispenser',
      headerName: 'HSD Power Dispenser Count',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'MS Dispenser Count',
      field: 'ms_dispenser_count',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'ms_power_dispenser',
      headerName: 'MS Power Dispenser Count',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Contact',
      field: 'contact',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'ACTION',
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.EDITBRANCH, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.VIEWBRANCH, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        return (
          <>
            {' '}
            <CustomButton
              text={'VIEW'}
              className='mx-3'
              color={'secondary'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={'EDIT'}
              className='mx-3 '
              onClick={handleEditClick}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      id: 1,
      name: 'Bharath',
      ms_tank: 1,
      hsd_tank: '2',
      tank_count: 3,
      hsd_dispenser_count: 3,
      hsd_power_dispenser: 2,
      ms_dispenser_count: '2',
      ms_power_dispenser: '2',
      contact: '8946543155',
      address: 'SDFASDFADSF',
    },
    {
      id: 2,
      name: 'Bharath',
      ms_tank: 1,
      hsd_tank: '2',
      tank_count: 3,
      hsd_dispenser_count: 3,
      hsd_power_dispenser: 2,
      ms_dispenser_count: '2',
      ms_power_dispenser: '2',
      contact: '8946543155',
      address: 'SDFASDFADSF',
    },
  ];
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <BranchHeader
          view={true}
          header={'Branch'}
          buttonName={'Add Branch'}
          route={APP_ROUTES.ADDBRANCH}
          buttonIcon={AddIcon}
        />
      </Grid>

      {loading ? (
        <>
          <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
            <CircularIndeterminate />
          </Grid>{' '}
        </>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default Branch;
