import { Grid } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { fuelType, Role } from '../../../common/constants/dropDownList';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TankFrom({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
}) {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('BRANCH')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={Role}
              ClassName='form-select'
              placeholder={t('BRANCH')}
              inputProps={register('bunk_details_id', {
                required: {
                  value: true,
                  message: t('SELECT_THE_BRANCH'),
                },
              })}
              name={'bunk_details_id'}
              error={errors}
              readOnly={view}
              defaultValue={location.state.data?.data?.bunk_details_id}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TANK_TYPE')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={fuelType}
              ClassName='form-select'
              placeholder={t('TANK_TYPE')}
              inputProps={register('tank_type', {
                required: {
                  value: true,
                  message: t('SELECT_THE_TANK_TYPE'),
                },
              })}
              name={'tank_type'}
              error={errors}
              readOnly={view}
              defaultValue={location.state.data?.data?.tank_type}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TANK_CAPACITY')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='tank_capacity'
              type='text'
              placeholder={t('TANK_CAPACITY')}
              control={control}
              readOnly={view}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_THE_TANK_CAPACITY'),
                },

                pattern: {
                  value: /^[0-9]*$/,
                  message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
                },
                minLength: {
                  value: 1,
                  message: t('CAPACITY_SHOULD_EXCEES_ATLEAST_1_DIGITD_LONG'),
                },
              }}
              inputProps={{
                ...register('tank_capacity', {}),
              }}
              errors={errors}
            />
          </Grid>
          {view ? null : (
            <Grid
              item
              xs={12}
              justifyContent='flex-end'
              display={'flex'}
              my={5}
            >
              {reset && (
                <CustomButton
                  type='button'
                  text={t('RESET')}
                  // startIcon={ArrowBackIcon}
                  color={'primary'}
                  className='p-12 mx-10 color-gray'
                  onClick={() => {
                    reset();
                  }}
                />
              )}

              <CustomButton
                type='submit'
                // startIcon={AddIcon}
                text={t('SUBMIT')}
                color={'secondary'}
                className='p-12'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}
export default TankFrom;
