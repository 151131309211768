import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
const RoleFrom = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      role: '',
    },
  });

  const onSubmit = (data: any) => {
    navigate(APP_ROUTES.EDITROLE, { state: data });
  };
  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          <Grid container xs={12} p={2}>
            <Grid item xs={6} alignItems={'center'} display={'flex'}>
              <Typography
                fontSize={20}
                fontWeight={600}
                color={theme.palette.info.dark}
              >
                Add New Role
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={'center'}
              justifyContent={'flex-end'}
              display={'flex'}
            >
              <CustomButton
                type='button'
                text='Go Back'
                startIcon={SubdirectoryArrowRightIcon}
                color={'primary'}
                className='p-8 color-gray'
                onClick={() => {
                  navigate(APP_ROUTES.ROLE);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} p={2}></Grid>
          <Grid item xs={12} p={2} mb={5}>
            <LabelWithIcon
              label={'Role'}
              justifyContent='flex-start'
              className='mb-6  '
              labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
            />
            <FormInput
              name='role'
              type='text'
              placeholder='Role Name..'
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a Role',
                },
              }}
              inputProps={{
                ...register('role', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} justifyContent='flex-end' display={'flex'} my={5}>
            <CustomButton
              type='button'
              text='reset'
              color={'primary'}
              className='p-12 mx-10 color-gray'
              onClick={() => {
                reset();
              }}
            />
            <CustomButton
              type='submit'
              text='Save Role'
              color={'warning'}
              className='p-12'
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default RoleFrom;
