import { Grid } from '@mui/material';
import React from 'react';
import BranchHeader from './BranchHeader';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useNavigate } from 'react-router-dom';
import BranchStep from './BranchStep';

function AddBranch() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      ms_tank: '',
      hsd_tank: '',
      hsd_dispenser_count: '',
      hsd_power_dispenser: '',
      ms_power_dispnser: '',
      ms_dispenser_count: '',
      email: '',
      contact: '',
      address: '',
    },
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    // navigate(APP_ROUTES.BRANCH);
  };
  return (
    <Grid container mb={5}>
      <Grid item xs={12} p={2}>
        <BranchHeader
          view={true}
          header={'Add Branch'}
          route={APP_ROUTES.BRANCH}
          buttonName={'Go Back'}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid item xs={12}>
        <BranchStep
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        {/* <BranchForm /> */}
      </Grid>
    </Grid>
  );
}

export default AddBranch;
