import { Grid } from '@mui/material';
import React from 'react';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import CustomerForm from './CustomerForm';

function EditCustomer() {
  const location = useLocation();
  console.log("location",location.state.role)
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      customer_name: location.state.role?.customer_name || '',
      address: location.state.role?.address || '',
     
      email: location.state.role?.email || '',
      petrol_bunk_id: location.state.role?.petrol_bunk_id || '',
      limit: location.state.role?.limit || '',
      due_amount: location.state.role?.due_amount || '',
      contact: location.state.role?.contact || '',
      paid: location.state.role?.paid || '',
 
  
    },
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate(APP_ROUTES.CUSTOMER);
  };
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={'Edit Customer'}
          route={APP_ROUTES.CUSTOMER}
          buttonName={'Go Back'}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>
      <Grid>
        <CustomerForm
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}
export default EditCustomer;
