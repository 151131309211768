import { Grid } from '@mui/material';
import React from 'react';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import { fuelType } from '../../../common/constants/dropDownList';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
function Dispenser() {
  const { t } = useTranslation();
  const loading = false;
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      headerName: t('BRANCH'),
      field: 'branch',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DISPENSER_MODEL'),
      field: 'dispenser_model',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DISPENSER_MAKER'),
      field: 'dispenser_maker',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        fuelType.find(
          (data: any) => Number(data.Code) === Number(row?.dispenser_maker)
        )?.Category,
    },

    {
      headerName: t('NOZZLES'),
      field: 'nozzle',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      headerName: t('OTP_DATE'),
      field: 'otp_date',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('VALID_UPTO'),
      field: 'valid_upto',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('ACTION'),
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.EDITDISPENSER, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.VIEWDISPENSER, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        const dateToCheck = new Date() < new Date(row.valid_upto);

        console.log(dateToCheck);
        return (
          <>
            {' '}
            <CustomButton
              text={t('VIEW')}
              className='mx-3'
              color={'secondary'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={t('EDIT')}
              className='mx-3 '
              onClick={handleEditClick}
              disabled={dateToCheck}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      id: '1',
      branch: 'Elango',
      dispenser_type: '',
      bunk_details_id: '1',
      dispenser_maker: 1,
      nozzle: 2,
      otp_date: '2024-12-31',
      dispenser_model: 'jha5',
      valid_upto: '2024-08-18',
      dispenser_details: [
        {
          nozzle_type: 1,
          callibration_factor: 'dsfa2',
          valid_upto: '2025-02-04',
          wm_date: '2024-02-05',
          wm_certificate_no: 'sdfajb544661',
          wm_certificate: '',
        },
        {
          nozzle_type: 1,
          callibration_factor: 'dsaf',
          valid_upto: '2025-02-04',
          wm_date: '2024-02-05',
          wm_certificate_no: 'dfas65',
          wm_certificate: '',
        },
      ],
    },
  ];
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('DISPENSER_CALLIBARTRION_DETAILS')}
          buttonName={t('ADD_DISPENSER')}
          route={APP_ROUTES.ADDDISPENSER}
          buttonIcon={AddIcon}
        />
      </Grid>
      {loading ? (
        <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
          <CircularIndeterminate />
        </Grid>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default Dispenser;
