import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import CustomButton from '../../../common/ui/CustomButton';
import Table from '../../../common/Table';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
import Header from '../../../common/Header';
import { useTranslation } from 'react-i18next';
const Customer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [Succesopen, setSuccesopen] = useState(false);
  const success = () => {
    setSuccesopen(true);
  };
  const handleDelete = () => {
    setOpen(true);
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
     {
      field: 'email',
      headerName: 'Email',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
        field: 'contact',
        headerName: 'Contact',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'address',
        headerName: 'Address',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'petrol_bunk_id',
        headerName: 'Bunk Id',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'limit',
        headerName: 'Limit',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'due_amount',
        headerName: 'Due Amount',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'paid',
        headerName: 'Paid',
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
    {
      field: 'status',
      headerName: 'Status',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },

    {
      field: 'Action',
      headerName: 'Action',
      width: 190,
      disableColumnMenu: true,
      renderCell: ({ row }: any) => (
        <>
        {console.log("row",row)}
          <CustomButton
            text={'Edit'}
            className='mx-5 '
            color={'warning'}
            onClick={() => {
              navigate(APP_ROUTES.CUSTOMEREDIT, { state: { role: row } });
            }}
          />
          <CustomButton
            text={'Delete'}
            className='mx-3 color-gray'
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];
  const rows = [
    {
      id: 1,
      status: 'ACTIVE',
      customer_name: 'Customer',
      email:'example@gmail.com',
      contact:'9925265869',
      address:'abc street',
      petrol_bunk_id:1,
      limit:100,
      due_amount:500,
      paid:200
    },
    {
      id: 2,
      status: 'ACTIVE',
    customer_name: 'Customer1',
    email:'example@gmail.com',
    contact:'9925265869',
    address:'abc street',
      petrol_bunk_id:11,
      limit:100,
      due_amount:500,
      paid:150
    },
    {
      id: 3,
      status: 'ACTIVE',
      customer_name: 'Customer2',
       email:'example@gmail.com',
        contact:'9925265869',
        address:'abc street',
        petrol_bunk_id:1,
        limit:100,
        due_amount:500,
        paid:200
    },
    {
      id: 4,
      status: 'ACTIVE',
      customer_name: 'Customer3',
       email:'example@gmail.com',
        contact:'9925265869',
        address:'abc street',
        petrol_bunk_id:1,
        limit:100,
        due_amount:500,
        paid:200
    },
    {
      id: 5,
      status: 'ACTIVE',
      customer_name: 'Customer4',
       email:'example@gmail.com',
        contact:'9925265869',
        address:'abc street',
        petrol_bunk_id:1,
        limit:100,
        due_amount:500,
        paid:200
    },
    {
      id: 6,
      status: 'ACTIVE',
      customer_name: 'Customer5',
       email:'example@gmail.com',
        contact:'9925265869',
        address:'abc street',
        petrol_bunk_id:1,
        limit:100,
        due_amount:500,
        paid:200
    },
    {
      id: 7,
      status: 'ACTIVE',
      customer_name: 'Customer6',
       email:'example@gmail.com',
        contact:'9925265869',
        address:'abc street',
        petrol_bunk_id:1,
        limit:100,
        due_amount:500,
        paid:200
    },
  ];
  return (
    <Grid container>
      <Grid xs={12}>
        <Header
          view={true}
          header={'Customer'}
          buttonName={"Add Customer"}
          route={APP_ROUTES.CUSTOMERADD}
          buttonIcon={AddIcon}
        />
      </Grid>

      <Grid xs={12} my={5}>
        <Table columns={columns} rows={rows} />
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} />
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
    </Grid>
  );
};

export default Customer;
