import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
// import PageNotFound from '../common/ui/PageNotFound';

import AppLayout from './AppLayout';
import DashboardScreen from '../features/App/DashboardScreen/DashboardScreen';

import ProfileScreen from '../features/App/ProfileScreen/ProfileScreen';
import Branch from '../features/App/Branch/Branch';
import AddBranch from '../features/App/Branch/AddBranch';
import EditBranch from '../features/App/Branch/EditBranch';
import ViewBranch from '../features/App/Branch/ViewBranch';
import Employee from '../features/App/Employee/Employee';
import AddEmployee from '../features/App/Employee/AddEmployee';
import EditEmploye from '../features/App/Employee/EditEmploye';
import ViewEmployee from '../features/App/Employee/ViewEmployee';
import Tank from '../features/App/Tank/Tank';
import AddTank from '../features/App/Tank/AddTank';
import EditTank from '../features/App/Tank/EditTank';
import ViewTank from '../features/App/Tank/ViewTank';
import Dispenser from '../features/App/Dispenser/Dispenser';
import AddDispenser from '../features/App/Dispenser/AddDispenser';
import EditDispenser from '../features/App/Dispenser/EditDispenser';
import ViewDispenser from '../features/App/Dispenser/ViewDispenser';
import Fuel from '../features/App/Fuel/Fuel';
import AddFuel from '../features/App/Fuel/AddFuel';
import EditFuel from '../features/App/Fuel/EditFuel';
import ViewFuel from '../features/App/Fuel/ViewFuel';
import Role from '../features/App/Role/Role';
import RoleFrom from '../features/App/Role/RoleFrom';
import RoleEdit from '../features/App/Role/RoleEdit';
import DailyDipReading from '../features/App/DailyDipReading/DailyDipReading';
import AddDipReading from '../features/App/DailyDipReading/AddDipReading';
import EditDipReading from '../features/App/DailyDipReading/EditDipReading';
import ViewDipReading from '../features/App/DailyDipReading/ViewDipReading';
import EmployeeAttendance from '../features/App/EmployeeAttendance/EmployeeAttendance';
import AddAttendance from '../features/App/EmployeeAttendance/AddAttendance';
import EditAttendance from '../features/App/EmployeeAttendance/EditAttendance';
import ViewAttendance from '../features/App/EmployeeAttendance/ViewAttendance';
import Customer from '../features/App/customer/Customer';
import AddCustomer from '../features/App/customer/AddCustomer';
import EditCustomer from '../features/App/customer/EditCustomer';
import Bunk from '../features/App/Bunk/Bunk';
import AddBunk from '../features/App/Bunk/AddBunk';
import EditBunk from '../features/App/Bunk/EditBunk';
import ViewBunk from '../features/App/Bunk/ViewBunk';
import ChangePassword from '../features/App/ChangePassword/ChangePassword';

const AppRoutes = ({ isLogged }: any) => {
  const location = useLocation();

  return (
    <Routes>
      {isLogged === true ? (
        <Route path='/' element={<AppLayout />}>
          <Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
          <Route path={APP_ROUTES.BRANCH} element={<Branch />} />
          <Route path={APP_ROUTES.ADDBRANCH} element={<AddBranch />} />
          <Route path={APP_ROUTES.PROFILE} element={<ProfileScreen />} />
          <Route path={APP_ROUTES.EDITBRANCH} element={<EditBranch />} />
          <Route path={APP_ROUTES.VIEWBRANCH} element={<ViewBranch />} />
          <Route path={APP_ROUTES.EMPLOYEE} element={<Employee />} />
          <Route path={APP_ROUTES.ADDEMPLOYEE} element={<AddEmployee />} />
          <Route path={APP_ROUTES.EDITEMPLOYEE} element={<EditEmploye />} />
          <Route path={APP_ROUTES.VIEWEMPLOYEE} element={<ViewEmployee />} />
          <Route path={APP_ROUTES.TANK} element={<Tank />} />
          <Route path={APP_ROUTES.ADDTANK} element={<AddTank />} />
          <Route path={APP_ROUTES.EDITTANK} element={<EditTank />} />
          <Route path={APP_ROUTES.VIEWTANK} element={<ViewTank />} />
          <Route path={APP_ROUTES.DISPENSER} element={<Dispenser />} />
          <Route path={APP_ROUTES.ADDDISPENSER} element={<AddDispenser />} />
          <Route path={APP_ROUTES.EDITDISPENSER} element={<EditDispenser />} />
          <Route path={APP_ROUTES.VIEWDISPENSER} element={<ViewDispenser />} />
          <Route path={APP_ROUTES.FUEL} element={<Fuel />} />
          <Route path={APP_ROUTES.ADDFUEL} element={<AddFuel />} />
          <Route path={APP_ROUTES.EDITFUEL} element={<EditFuel />} />
          <Route path={APP_ROUTES.VIEWFUEL} element={<ViewFuel />} />
          <Route path={APP_ROUTES.ROLE} element={<Role />} />
          <Route path={APP_ROUTES.ADDROLE} element={<RoleFrom />} />
          <Route path={APP_ROUTES.EDITROLE} element={<RoleEdit />} />
          <Route path={APP_ROUTES.DIP} element={<DailyDipReading />} />
          <Route path={APP_ROUTES.DIPADD} element={<AddDipReading />} />
          <Route path={APP_ROUTES.DIPEDIT} element={<EditDipReading />} />
          <Route path={APP_ROUTES.DIPVIEW} element={<ViewDipReading />} />
          <Route path={APP_ROUTES.BUNK} element={<Bunk />} />
          <Route path={APP_ROUTES.BUNKADD} element={<AddBunk />} />
          <Route path={APP_ROUTES.BUNKEDIT} element={<EditBunk />} />
          <Route path={APP_ROUTES.BUNKVIEW} element={<ViewBunk />} />
          <Route
            path={APP_ROUTES.CHANGEPASSWORD}
            element={<ChangePassword />}
          />
          <Route
            path={APP_ROUTES.ATTENDANCES}
            element={<EmployeeAttendance />}
          />
          <Route path={APP_ROUTES.ATTENDANCESADD} element={<AddAttendance />} />
          <Route
            path={APP_ROUTES.ATTENDANCESEDIT}
            element={<EditAttendance />}
          />
          <Route
            path={APP_ROUTES.ATTENDANCESVIEW}
            element={<ViewAttendance />}
          />
          <Route path={APP_ROUTES.CUSTOMER} element={<Customer />} />
          <Route path={APP_ROUTES.CUSTOMERADD} element={<AddCustomer />} />
          <Route path={APP_ROUTES.CUSTOMEREDIT} element={<EditCustomer />} />

          <Route
            path={APP_ROUTES.CHANGEPASSWORD}
            element={<ChangePassword />}
          />

          {!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
            <Route path='*' element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
          ) : (
            <></>
          )}
        </Route>
      ) : (
        <></>
      )}
    </Routes>
  );
};

export default AppRoutes;
