import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import {
  fuelType,
  nozzleCount,
  Role,
} from '../../../common/constants/dropDownList';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import NozzleFrom from './NozzleFrom';
import { useLocation } from 'react-router-dom';

function DispenserFrom({
  reset,
  handleSubmit,
  onSubmit,
  control,
  register,
  errors,
  view = false,
  watch,
  setValue,
  edit,
}: {
  reset?: any;
  handleSubmit: any;
  onSubmit?: any;
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
  watch: any;
  setValue: any;
  edit?: any;
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dispenser_details',
  });
  const nozzle = watch('nozzle');
  useEffect(() => {
    for (let i = 0; i < nozzle; i++) {
      const nozzles = {
        nozzle_type: edit
          ? ''
          : location.state.data?.data?.dispenser_details[i]?.nozzle_type || '',
        callibration_factor: edit
          ? ''
          : location.state.data?.data?.dispenser_details[i]
              ?.callibration_factor || '',
        valid_upto: edit
          ? ''
          : location.state.data?.data?.dispenser_details[i]?.valid_upto || '',
        wm_date: edit
          ? ''
          : location.state.data?.data?.dispenser_details[i]?.wm_date || '',
        wm_certificate_no: edit
          ? ''
          : location.state.data?.data?.dispenser_details[i]
              ?.wm_certificate_no || '',
        wm_certificate: edit
          ? ''
          : location.state.data?.data?.dispenser_details[i]?.wm_certificate ||
            '',
        // wm_seal_details:
        //   location.state.data?.data?.dispenser_details[i]?.wm_seal_details ||
        //   '',
      };
      append(nozzles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nozzle]);

  return (
    <div>
      {' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container display='flex' justifyContent='flex-start'>
          {edit && (
            <Grid item xs={12} p={2}>
              <LabelWithIcon
                label={t('REASON')}
                justifyContent='flex-start'
                className='mb-6 '
              />
              <FormInput
                name={`reason`}
                type='text'
                placeholder={t('REASON')}
                control={control}
                readOnly={view}
                className='input-from'
                rules={{
                  required: {
                    value: true,
                    message: t('PLEASE_ENTER_REASON'),
                  },
                }}
                inputProps={{
                  ...register(`reason`, {}),
                }}
                errors={errors}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('BRANCH')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={Role}
              ClassName='form-select'
              placeholder={t('BRANCH')}
              inputProps={register('bunk_details_id', {
                required: {
                  value: true,
                  message: t('SELECT_THE_BRANCH'),
                },
              })}
              name={'bunk_details_id'}
              error={errors}
              readOnly={view || edit}
              value={watch('bunk_details_id')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('DISPENSER_MAKER')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={fuelType}
              ClassName='form-select'
              placeholder={t('DISPENSER_MAKER')}
              inputProps={register('dispenser_maker', {
                required: {
                  value: true,
                  message: t('SELECT_THE_DISPENSER_MAKER'),
                },
              })}
              name={'dispenser_maker'}
              error={errors}
              readOnly={view || edit}
              value={watch('dispenser_maker')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('NOZZLES')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={nozzleCount}
              ClassName='form-select'
              placeholder={t('NOZZLES')}
              inputProps={register('nozzle', {
                required: {
                  value: true,
                  message: t('PLEASE_ENTER_THE_NOZZLE'),
                },
              })}
              name={'nozzle'}
              error={errors}
              readOnly={view || edit}
              value={watch('nozzle')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('OTP_DATE')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`otp_date`}
              type='date'
              placeholder='date..'
              control={control}
              className='input-from'
              inputProps={{
                ...register(`otp_date` as const),
              }}
              rules={{
                required: {
                  value: true,
                  message: t('SELECT_THE_OTP_DATE'),
                },
              }}
              readOnly={view || edit}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('DISPENSER_MODEL')}
              justifyContent='flex-start'
              className='mb-6 '
            />
            {/* <CustomSelectBox
              options={fuelType}
              ClassName='form-select'
              placeholder={t('DISPENSER_MODEL')}
              inputProps={register('dispenser_model', {
                required: {
                  value: true,
                  message: t('SELECT_THE_DISPENSER_MODEL'),
                },
              })}
              name={'dispenser_model'}
              error={errors}
              readOnly={view}
              value={watch('dispenser_model')}
            /> */}
            <FormInput
              name='dispenser_model'
              type='text'
              placeholder={t('DISPENSER_MODEL')}
              control={control}
              readOnly={view || edit}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: t('SELECT_THE_DISPENSER_MODEL'),
                },
              }}
              inputProps={{
                ...register('dispenser_model', {}),
              }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} p={2}>
            <LabelWithIcon
              label={t('W&M_SEAL_DETAILS')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('METERING_UNIT_1')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`metering_unit_1`}
              type='text'
              placeholder={t('METERING_UNIT_1')}
              control={control}
              className='input-from'
              readOnly={view}
              inputProps={{
                ...register(`metering_unit_1` as const),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('METERING_UNIT_2')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`metering_unit_2`}
              type='text'
              placeholder={t('METERING_UNIT_2')}
              control={control}
              readOnly={view}
              className='input-from'
              inputProps={{
                ...register(`metering_unit_2` as const),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('METERING_UNIT_3')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`metering_unit_3`}
              type='text'
              placeholder={t('METERING_UNIT_3')}
              control={control}
              readOnly={view}
              className='input-from'
              inputProps={{
                ...register(`metering_unit_3` as const),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('METERING_UNIT_4')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`metering_unit_4`}
              type='text'
              placeholder={t('METERING_UNIT_4')}
              control={control}
              readOnly={view}
              className='input-from'
              inputProps={{
                ...register(`metering_unit_4` as const),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TOTALIZER_1')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`totalizer_1`}
              type='text'
              placeholder={t('TOTALIZER_1')}
              control={control}
              readOnly={view}
              className='input-from'
              inputProps={{
                ...register(`totalizer_1` as const),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TOTALIZER_2')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`totalizer_2`}
              type='text'
              placeholder={t('TOTALIZER_2')}
              control={control}
              readOnly={view}
              className='input-from'
              inputProps={{
                ...register(`totalizer_2` as const),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TOTALIZER_3')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`totalizer_3`}
              type='text'
              placeholder={t('TOTALIZER_3')}
              control={control}
              readOnly={view}
              className='input-from'
              inputProps={{
                ...register(`totalizer_3` as const),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('TOTALIZER_4')}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name={`totalizer_4`}
              type='text'
              placeholder={t('TOTALIZER_4')}
              control={control}
              readOnly={view}
              className='input-from'
              inputProps={{
                ...register(`totalizer_4` as const),
              }}
            />
          </Grid>

          {fields.map((item: any, index: number) => (
            <NozzleFrom
              index={index}
              control={control}
              errors={errors}
              watch={watch}
              view={view}
              register={register}
              remove={remove}
              setValue={setValue}
            />
          ))}
          {view ? null : (
            <Grid
              item
              xs={12}
              justifyContent='flex-end'
              display={'flex'}
              my={5}
            >
              {reset && (
                <CustomButton
                  type='button'
                  text='reset'
                  // startIcon={ArrowBackIcon}
                  color={'primary'}
                  className='p-12 mx-10 color-gray'
                  onClick={() => {
                    reset();
                  }}
                />
              )}

              <CustomButton
                type='submit'
                // startIcon={AddIcon}
                text='Submit'
                color={'secondary'}
                className='p-12'
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}
export default DispenserFrom;
