import { Grid } from '@mui/material';
import React from 'react';
import BranchHeader from './BranchHeader';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useForm } from 'react-hook-form';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useLocation, useNavigate } from 'react-router-dom';
import BranchStep from './BranchStep';

function ViewBranch() {
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: location.state.data?.data?.name || '',
      ms_tank: location.state.data?.data?.ms_tank || '',
      hsd_tank: location.state.data?.data?.hsd_tank || '',
      hsd_dispenser_count: location.state.data?.data?.hsd_dispenser_count || '',
      hsd_power_dispenser: location.state.data?.data?.hsd_power_dispenser || '',
      ms_power_dispenser: location.state.data?.data?.ms_power_dispenser || '',
      ms_dispenser_count: location.state.data?.data?.ms_dispenser_count || '',
      email: location.state.data?.data?.email || 'keerthivk@gmail.com',
      contact: location.state.data?.data?.contact || '',
      address: location.state.data?.data?.address || '',
      docket_no: 'dfasd',
      licence_date: '2023-09-06',
      valid_upto: '2025-09-06',
      certificate: 'aba.pdf',
      license_no: 'czxC',
      license_certificate: 'aba.pdf',
      DMNOC_no: 'cXZ',
      DMNOC_certificate: 'aba.pdf',
      shop_license_no: 'daD',
      shop_certificate: 'aba.pdf',
      agreement_date: '2025-09-06',
      agreement_certificate: 'aba.pdf',
      gst_no: 'dsfafasd',
      gst_Certificate_date: '2025-09-06',
      GST_certificate: 'aba.pdf',
      MSME_certificate_date: '2025-09-06',
      MSME_certificate: 'aba.pdf',
      electricity_certificate_date: '1998-09-05',
      electricity_certificate: 'aba.pdf',
      water_connections_certificate_date: '2025-02-06',
      water_connections_certificate: 'aba.pdf',
      property_certificate_date: '2025-09-06',
      property_certificate: 'aba.pdf',
    },
  });
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    navigate(APP_ROUTES.BRANCH);
  };
  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <BranchHeader
          view={true}
          header={'View Branch'}
          route={APP_ROUTES.BRANCH}
          buttonName={'Go Back'}
          buttonIcon={SubdirectoryArrowRightIcon}
        />
      </Grid>

      <Grid item xs={12}>
        <BranchStep
          reset={reset}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          view={true}
        />
        {/* <BranchForm /> */}
      </Grid>
    </Grid>
  );
}

export default ViewBranch;
