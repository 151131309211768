import React, { useState } from 'react';
import LabelWithIcon from './ui/LabelWithIcon';
import { FormHelperText, useTheme } from '@mui/material';

function PdfUpload({
  control,
  register,
  errors,
  setValue,
  watch,
  name,
  errorName,
  errorMessage,
  secondName,
  labelName,
}: {
  control: any;
  register: any;
  errors: any;
  setValue: any;
  watch: any;
  name: string;
  errorName: any;
  errorMessage?: string;
  secondName: string;
  labelName: string;
}) {
  const theme = useTheme();
  const [fileName, setFileName] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      if (!selectedFile.name.endsWith('.pdf')) {
        setValue(name, ''); // Clear the file name if invalid
        setFileName(null); // Clear the file name display
        e.target.value = ''; // Clear the file input
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent: any) => {
        const base64EncodedFile = fileLoadedEvent.target.result;
        const base64WithoutPrefix = base64EncodedFile.slice(28); // Remove the data URL prefix
        const uri = JSON.stringify(base64WithoutPrefix);
        setValue(name, uri); // Update form value with base64-encoded file
      };

      fileReader.readAsDataURL(selectedFile);
      setValue(secondName, selectedFile.name); // Update form value with file name
      setFileName(selectedFile.name); // Update file name display
    } else {
      setValue(name, ''); // Clear the value if no file is selected
      setFileName(null); // Clear the file name display
    }
  };
  console.log('sadf', errors);
  return (
    <div>
      <LabelWithIcon
        label={labelName}
        justifyContent='flex-start'
        className='mb-6'
      />
      <input
        name={name}
        type='hidden'
        className='pdf-form'
        {...register(name, {
          required: errorMessage ? errorMessage : false,
        })}
      />
      <input
        name={name}
        type='file'
        className='pdf-form'
        onChange={(e) => {
          handleFileChange(e);
        }}
      />

      {/* Display the file name if a file is selected */}
      {/* {fileName && <div className='file-name-display'>{fileName}</div>} */}

      {/* Display error message */}
      {errors
        ? errors[errorName]?.message && (
            <FormHelperText
              className='error-text'
              error={true}
              sx={{ color: theme.palette.error.dark }}
            >
              {errors[errorName]?.message}
            </FormHelperText>
          )
        : null}
    </div>
  );
}

export default PdfUpload;
