import { Grid } from '@mui/material';
import React from 'react';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import PdfUpload from '../../../common/PdfUpload';
import { useTranslation } from 'react-i18next';

function MSME({
  control,
  register,
  errors,
  view = false,
  watch,
  setValue,
}: {
  control?: any;
  register: any;
  errors?: any;
  view?: boolean;
  watch: any;
  setValue: any;
}) {
  const { t } = useTranslation();
  return (
    <div>
      {' '}
      <Grid container display='flex' justifyContent='flex-start'>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={t('Certificate date')}
            justifyContent='flex-start'
            className='mb-6 ml-3'
          />
          <FormInput
            name={`MSME_certificate_date`}
            type='date'
            placeholder='valid_upto..'
            control={control}
            className='input-from'
            inputProps={{
              ...register(`MSME_certificate_date` as const),
            }}
            rules={{
              required: {
                value: false,
                message: t('SELECT_THE_CERTIFICATE_DATE'),
              },
            }}
            errorName={'MSME_certificate_date'}
            errors={errors}
            readOnly={view}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <PdfUpload
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            name={`MSME_certificate`}
            errorName={`MSME_certificate`}
            secondName={`MSME_certificate`}
            labelName={t('CERTIFICATE')}
            // errorMessage={t('PLEASER_SELECT_THE_CERTIFICATE')}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default MSME;
