import { Grid } from '@mui/material';
import React from 'react';
import CircularIndeterminate from '../../../common/CircularLoading';
import Table from '../../../common/Table';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import { fuelType } from '../../../common/constants/dropDownList';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
function DailyDipReading() {
  const { t } = useTranslation();
  const loading = false;
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      headerName: t('TANK_ID'),
      field: 'tank_serai_no',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DATE'),
      field: 'entry_date',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t('DIPREADING'),
      field: 'dip_reading',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        fuelType.find(
          (data: any) => Number(data.Code) === Number(row?.dispenser_type)
        )?.Category,
    },
    {
      headerName: t('TANK_FILLED'),
      field: 'tank_stock',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        fuelType.find(
          (data: any) => Number(data.Code) === Number(row?.dispenser_type)
        )?.Category,
    },
    {
      headerName: t('TANK_EMPTY'),
      field: 'ullage',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) =>
        fuelType.find(
          (data: any) => Number(data.Code) === Number(row?.dispenser_type)
        )?.Category,
    },

    {
      headerName: t('ACTION'),
      field: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }: any) => {
        const handleEditClick = () => {
          navigate(APP_ROUTES.DIPEDIT, {
            state: { data: { data: row, type: 'edit' } },
          });
        };

        const handleViewClick = () => {
          navigate(APP_ROUTES.DIPVIEW, {
            state: { data: { data: row, type: 'view' } },
          });
        };

        return (
          <>
            {' '}
            <CustomButton
              text={t('VIEW')}
              className='mx-3'
              color={'secondary'}
              onClick={handleViewClick}
            />
            <CustomButton
              text={t('EDIT')}
              className='mx-3 '
              onClick={handleEditClick}
            />
          </>
        );
      },
    },
  ];
  const data: any[] = [
    {
      id: 'a96907cd-1072-40c8-977e-9f8140962f35',

      tank_id: '1',
      tank_serai_no: 'PEOO1',
      user_id: '1',
      entry_date: '2024-08-12T17:00',
      dip_reading: '2000',
      tank_stock: '2000',
      ullage: '8000',
    },
    {
      id: '6b2e5c12-d322-4a2e-804e-4cc19186f099',
      tank_id: '1',
      tank_serai_no: 'PEOO1',
      user_id: '1',
      entry_date: '2024-08-12T17:00',
      dip_reading: '2000',
      tank_stock: '2000',
      ullage: '8000',
    },
    {
      id: '3',
      tank_id: '1',
      tank_serai_no: 'PEOO1',
      user_id: '1',
      entry_date: '2024-08-12T17:00',
      dip_reading: '2000',
      tank_stock: '2000',
      ullage: '8000',
    },
    {
      id: '4',
      tank_id: '1',
      tank_serai_no: 'PEOO1',
      user_id: '1',
      entry_date: '2024-08-12T17:00',
      dip_reading: '2000',
      tank_stock: '2000',
      ullage: '8000',
    },
  ];

  return (
    <Grid container mb={5}>
      <Grid xs={12} p={2}>
        <Header
          view={true}
          header={t('DIPREADING')}
          buttonName={t('ADD_DIP_READING')}
          route={APP_ROUTES.DIPADD}
          buttonIcon={AddIcon}
        />
      </Grid>
      {loading ? (
        <Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
          <CircularIndeterminate />
        </Grid>
      ) : (
        <Grid xs={12} mb={5}>
          <Table
            // totalRecords={
            //   petitionTypeCount
            //     ? petitionTypeCount
            //     : petetionList?.totalRecords || 0
            // }
            rows={data}
            columns={columns}
            // handlePagination={handlePagination}
            // paginationModel={{
            //   page: Number(petetionList?.currentPage) - 1 || 0,
            //   pageSize: Number(petetionList?.pagesize) || 5,
            // }}
            rowHeight={60}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default DailyDipReading;
